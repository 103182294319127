import React from 'react';
import Modal from 'react-modal';

import IconsClose from "../assets/icons/icons-close.svg";
 
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/          react-modal/accessibility/)
Modal.setAppElement('#app-root')

const ModalBox = ({modalIsOpen, closeModal, openModal, title = "Modal", children}) => {
    return (
        <Modal
            isOpen={modalIsOpen}
            openModal={openModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel={title}
        >
            <div className="modal__box text-center card padding-md card--rounded margin">
                <div onClick={closeModal} className="modal__close">
                    <img src={IconsClose} />
                </div>
                <h4>{title}</h4>
                {children}
            </div>
        </Modal>
    )
}
 
export default ModalBox;