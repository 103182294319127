import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import {
    FormCardLimitSelect,
    FormCardTypeSelect,
    FormEmploymentTypeSelect
} from "../../components";

import {
    CARD_REQUEST_MUTATION,
    USER_ACTIVITY_QUERY,
    USER_LOAN_QUERY
} from "../../graphql";

import { useUser } from "../../hooks";

const CardApplyForm = ({ onFinish }) => {
    const { register, errors, handleSubmit, control, reset } = useForm()
    const [performCardRequest, { loading }] = useMutation(CARD_REQUEST_MUTATION);
    const me = useUser();

    const submitForm = async data => {
        if (data === "") {
            errors.showMessages();
            return;
        }

        try {
             await performCardRequest({
                variables: {
                name: me.name,
                email: me.email,
                phone: me.phone,
                street: me.residentialAddress.street,
                city: me.residentialAddress.city,
                suite: me.residentialAddress.suite,
                postal: me.residentialAddress.postal,
                province: me.residentialAddress.province,
                country: "Canada",
                dob: me.dob,
                gender: me.gender,
                employerName: data.employerBusinessName,
                employerPhone: "",
                employerJobTitle: data.jobTitle,
                monthlyIncome: data.monthlyIncome,
                cardType: data.cardType.value,
                cardLimit: data.cardTest.value,
                cardFee: "$120"
                },
                refetchQueries: [
                    {
                        query: USER_ACTIVITY_QUERY
                    },
                    {
                        query: USER_LOAN_QUERY
                    }
                ]
            },);

            reset();
            onFinish();
        } catch (error){
            toast.error(error.message.replace("GraphQL error: ", ""));
        }
    }

    return (
        <form className="form" noValidate="" onSubmit={handleSubmit(submitForm)}>
            { loading && <div className="loading"></div> }
            <fieldset disabled={loading}>
                {
                    Object.keys(errors).length > 0 && 
                    <div className="form__message">
                        {errors.cardType && <span>Select a card type</span>}
                        {errors.cardTest && <span>Select a card limit</span>}
                        {errors.purpose && <span>Enter purpose of credit card</span>}
                        {errors.monthlyIncome && <span>Enter your monthly income</span>}
                        {errors.jobTitle && <span>Enter your job title</span>}
                        {errors.employerBusinessName && <span>Enter your employer's business name</span>}
                    </div>
                }
                <div className="form__group">
                    <div className="form__col form__select">
                        <FormCardTypeSelect
                            control={control}
                            name="cardType"
                            rules={{
                              required: true,
                              maxLength: 10,
                            }}
                        />
                    </div>
                    <div className="form__col form__select">
                        <FormCardLimitSelect
                            control={control}
                            rules={{
                              required: true,
                              maxLength: 10,
                            }}
                        />
                    </div>
                </div>
                <div className="form__group">
                    <div className="form__col">
                        <input type="text" placeholder="Annual Fee" value="Annual Fee: $120.00" disabled className="form-control" />
                    </div>
                    <div className="form__col">
                        <input type="text" placeholder="Purpose Of Credit Card" name="purpose" className="form-control" ref={register({ required: true })}  />
                    </div>
                </div>
    
                <div className="form__group">
                    <div className="form__col form__select">
                        <FormEmploymentTypeSelect
                            control={control}
                            rules={{
                              required: false,
                              maxLength: 10,
                            }}
                        />
                    </div>
                    <div className="form__col">
                        <input type="number" placeholder="Monthly Income" name="monthlyIncome" className="form-control" ref={register({ required: true })}  />
                    </div>
                </div>
                <div className="form__group">
                    <div className="form__col">
                        <input type="text" placeholder="Job Title" name="jobTitle" className="form-control" ref={register({ required: true })}  />
                    </div>
                    <div className="form__col">
                        <input type="text" placeholder="Employer Business Name" name="employerBusinessName" className="form-control" ref={register({ required: true })}  />
                    </div>
                </div>
                <div className="form__group">
                    <div className="form__col">
                        <div className="activation-disclaimer">*One-time activation fee will apply $20</div>
                    </div>
                    <div className="form__col">
                        </div>
                </div>
    
                <div className="disclaimer">
                    <header>How does a secured mastercard work?</header>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <div>
                    <button disabled={loading} style={{textDecoration: "none", width: "auto"}} className="btn btn-info margin-t-sm margin-b-md">Request Card</button>
                </div>
            </fieldset>
        </form>
    );
}

export default CardApplyForm;