import React, { useState } from "react";
import AppsTableRow from "./AppsTableRow";
import WelcomeCard from "./WelcomeCard";
import TableHeaderFilter from "./TableHeaderFilter";

const AppsTable = ({title, headers = [], data}) => {
    const hasRows =  !(!data || data === null || data.length === 0);
    const [tableData, setTableData] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    const handleSetFilteredData = (data) => setTableData(data);
    const handleSetSearchInput = (value) => setSearchInput(value);
    const dataToDisplay = searchInput.length ? tableData : data;

    if(!hasRows) 
        return <WelcomeCard hasCardStyle={false} hasContainer/> 
     
    return (
        <div className="table-responsive">
            <div className="table-wrapper"> 
                <TableHeaderFilter 
                    tableData={[...data]} 
                    tableTitle={title} 
                    handleSetFilteredData={handleSetFilteredData} 
                    handleSetSearchInput={handleSetSearchInput} 
                    columns={
                        [
                            {
                                label: "All",
                                value: ""
                            },
                            {
                                label: "Date",
                                value: "createdAt"
                            },
                            {
                                label: "Application Id",
                                value: "id"
                            },
                            {
                                label: "Type/Term",
                                value: "paymentCycleDuration"
                            },
                            {
                                label: "Amount/Limit",
                                value: "loanAmount"
                            },
                            {
                                label: "Adjudicator",
                                value: "adj"
                            },
                            {
                                label: "Status",
                                value: "status"
                            }
                        ]
                    }
                />
                <table>
                    <thead>
                    <tr> 
                        {
                         headers.map((header, i) => (
                                <th key={i} className={header.className ? header.className : "text-left"} style={{width: header.width ? header.width : "auto"}}>{header.title}</th>
                            ))
                        }
                    </tr>
                    </thead>
                    <tbody>
                        { dataToDisplay.map((row, i) => <AppsTableRow key={i} {...row} /> )  }
                    </tbody>
                </table>
            </div> 
        </div>
    )
}

export default AppsTable;