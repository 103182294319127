import React from "react";

import IconsEnglish from "../assets/svg/eng.svg";
import IconsSpain from "../assets/svg/spain.svg";
import IconsPort from "../assets/svg/port.svg";
import IconsFrench from "../assets/svg/french.svg";

const LangWidget = () => {
    const [isDropDownOpen, setDropDownOpen] = React.useState(false);

    return (
        <>
        <div className={`dropdown-menu dropdown-menu--lang`} style={{
            transform: isDropDownOpen ? "none" : "scaleY(0)",
            opacity: isDropDownOpen ? 1 : 0
        }}>
            <div className="dropdown-menu__item">
                <div className="dropdown-menu__icon">
                    <img src={IconsEnglish} alt="edit profile" />
                </div>
                English
            </div>
            <div className="dropdown-menu__item">
                <div className="dropdown-menu__icon">
                    <img src={IconsSpain} alt="edit profile" />
                </div>
                Spanish
            </div>
            <div className="dropdown-menu__item">
                <div className="dropdown-menu__icon">
                    <img src={IconsPort} alt="edit profile" />
                </div>
                Portuguese
            </div>
            <div className="dropdown-menu__item">
                <div className="dropdown-menu__icon">
                    <img src={IconsFrench} alt="edit profile" />
                </div>
                French
            </div>
        </div>
            <div onClick={() => setDropDownOpen(!isDropDownOpen)} className={`lang-widget  ${isDropDownOpen && "open"}`}></div>   
        </>
    )
};

export default LangWidget;