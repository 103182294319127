
import React from 'react';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useMutation } from "react-apollo";
import { AUTH_FORGOT_PASSWORD_REQUEST } from "../../../graphql";

import { toast } from "react-toastify";

// CSS
import "./styles.css";

// Images
import logoSmall from "../../../assets/images/logo-white.png";
import logoMedium from "../../../assets/images/logo-white@2x.png";
import logoLarge from "../../../assets/images/logo-white@3x.png";

const emailRegex = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  message: "invalid email address",
};

const ForgotPwd = ({ history }) => {
  const { errors, register, handleSubmit, reset } = useForm()
  const [sendRequest, { loading }] = useMutation(AUTH_FORGOT_PASSWORD_REQUEST)
  const onSubmit = async (data) => {
    if(data === ""){
      errors.showMessages();
      return;
    }
    try {
      await sendRequest({
       variables: {
        email: data.email
       }
      })
      toast.success("We have sent you a link to your email to reset your password!");
      reset();
    } catch (error){
      const message = error.message.replace("GraphQL error:" ,"");
      if(message === " TypeError: Cannot read property 'email' of null"){
        toast.error("Email address not found");
      }else{
        toast.error(message);
      }
    } 
  }
  return (
    <div className="login-grid">
        <header className="header">
            <Link className="margin-t-xl" to={`${process.env.PUBLIC_URL}/`}>
                <img 
                    className="margin-t-md header__logo"
                    srcSet={`
                    ${logoSmall} 1x,
                    ${logoMedium} 2x,
                    ${logoLarge} 3x   
                    `}
                    alt="Lendle"
                />
            </Link>
            <p className="header__subtitle">
                ushering a new era of banking, client focused.
            </p>
        </header>
        <main className="container">
            <div className="main__cards" style={{flex: 1}}>
                <div className="card padding-md card--rounded card--shadow margin">
                    <div className="card__header margin-b-sm">
                        <div className="card__title">
                            Forgot Password?
                        </div>
                        <div className="card__subtitle">
                            Enter the email address you used when you joined and we’ll send you instructions to reset your password.
                        </div>
                    </div>
                    <div className="card__body d-flex flex-column justify-content-center">
                        <form className="form" onSubmit={handleSubmit(onSubmit)}>
                            {loading && <span>Loading...</span>}
                            <fieldset aria-busy={loading} disabled={loading}>
                                <div className="form__row">
                                    <input 
                                        type="email" 
                                        placeholder="Your Email" 
                                        className="form-control"
                                        name="email" 
                                        ref={register({
                                            required: true,               
                                            pattern: emailRegex,
                                        })} 
                                        required
                                    />
                                    {errors.email && <span style={{color: "red"}}>Email is required</span>}
                                </div>
                                <div className="d-flex  align-items-center margin-t-sm margin-b-sm">
                                    <button style={{textDecoration: "none", marginRight: "1em"}} className="btn btn-info">Send Reset Instructions</button>
                                    <Link to={`${process.env.PUBLIC_URL}/login`} style={{textDecoration: "none"}} className="btn btn-info-outline">Back To Sign In</Link>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <div className="signup__link">
                <span>Don't have an account? <Link to={`${process.env.PUBLIC_URL}/signup`}>Sign Up</Link></span>
            </div>
        </main>
        <footer className="footer">
            <div className="footer__text">
                Copyright © Lendle 2020. All Rights reserved.
            </div>
        </footer>
    </div>
  );
};

export default ForgotPwd;
