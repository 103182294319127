import React from "react";

const useScore = ({initialScore = 100}) => {
    const [score, setScore] = React.useState(initialScore / 100);

    const updateScore = (value) => {
        if (value < 0 || value > 100) {
            return;
        }
        setScore(value / 100);
    }
    return {
        score,
        updateScore
    }
}

export default useScore;