import React from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { ScrollContext } from "react-router-scroll-4";
import { AuthRoute } from "../util";

import {
  Activity,
  Applications,
  Card,
  CardApply,
  Dashboard,
  Documents,
  LoanApply,
  ForgotPwd,
  SignUp,
  SignIn,
  Profile,
  Notifications
} from "../pages";

const Routes = () => (
  <BrowserRouter basename={"/"}>
    <ScrollContext>
      <Switch>
        <Route component={SignIn} path={`${process.env.PUBLIC_URL}/login`} />
        <Route component={SignUp} path={`${process.env.PUBLIC_URL}/signup`} />
        <Route component={ForgotPwd} path={`${process.env.PUBLIC_URL}/forgot-password`} />
        <Route component={(props) => <h1>Route {props.location.pathname}</h1>} path={`${process.env.PUBLIC_URL}/reset-password`} />
        <Route component={(props) => <h1>Route {props.location.pathname}</h1>} path={`${process.env.PUBLIC_URL}/verifyapp`} />
        <Route component={(props) => <h1>Route {props.location.pathname}</h1>} path={`${process.env.PUBLIC_URL}/mastercard`} />
        <AuthRoute component={Dashboard} title="Dashboard Overview" path={`${process.env.PUBLIC_URL}/dashboard/overview`} />
        <AuthRoute component={Documents} title="Documents" path={`${process.env.PUBLIC_URL}/dashboard/documents`} />
        <AuthRoute fullWidth component={LoanApply} title="Loan Application" exact path={`${process.env.PUBLIC_URL}/dashboard/request`} />
        <AuthRoute component={Profile} title="My Profile" exact path={`${process.env.PUBLIC_URL}/dashboard/me`} />
        <AuthRoute component={Applications} title="Applications" exact path={`${process.env.PUBLIC_URL}/dashboard/applications`} />
        <AuthRoute component={Activity} title="Account Activity" exact path={`${process.env.PUBLIC_URL}/dashboard/activity`} />
        <AuthRoute component={Notifications} title="Account Notifications" exact path={`${process.env.PUBLIC_URL}/dashboard/notifications`} />
        <Route component={(props) => <h1>Route {props.location.pathname}</h1>} path={`${process.env.PUBLIC_URL}/dashboard/verifyapp`} />
        <AuthRoute component={Card} title="Credit Card" exact path={`${process.env.PUBLIC_URL}/card`} />
        <AuthRoute component={CardApply} title="Secured Mastercard Application" exact path={`${process.env.PUBLIC_URL}/card/apply`} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          render={() => {
            return (
              <Redirect to={`${process.env.PUBLIC_URL}/dashboard/overview`} />
            );
          }} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/completesignup`}
          render={(props) => {
            return (
              <Redirect to={`${process.env.PUBLIC_URL}/dashboard/overview${props.location.search}`} />
            );
          }} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dashboard`}
          render={() => {
            return (
              <Redirect to={`${process.env.PUBLIC_URL}/dashboard/overview`} />
            );
          }} />
      </Switch>
    </ScrollContext>
  </BrowserRouter>
);

export default Routes;


