import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

// Client
import { withApollo } from "react-apollo";
import { client } from "../../../graphql/client";

// Auth hooks
import { useUser } from "../../../hooks";

// CSS
import "./styles.css";

// Images
import logoSmall from "../../../assets/images/logo-white.png";
import logoMedium from "../../../assets/images/logo-white@2x.png";
import logoLarge from "../../../assets/images/logo-white@3x.png";

// Components
import SignInForm from "./SignInForm";

const SignIn = ({ history }) => {
  const onSuccess = () => {
    client.cache.reset();
    history.push(`${process.env.PUBLIC_URL}/dashboard/overview`);
  };

  return (
    <div className="login-grid">
          <header className="header">
              <Link className="margin-t-xl" to={`${process.env.PUBLIC_URL}/`}>
                  <img 
                      className="margin-t-md header__logo"
                      srcSet={`
                       ${logoSmall} 1x,
                       ${logoMedium} 2x,
                       ${logoLarge} 3x   
                      `}
                      alt="Lendle"
                  />
              </Link>
              <p className="header__subtitle">
                  ushering a new era of banking, client focused.
              </p>
          </header>
          <main className="container">
              <div className="main__cards" style={{
                flex: 1,
                paddingRight: 0,
                paddingLeft: 0
              }}>
                  <div className="card padding-md card--rounded card--shadow margin">
                      <div className="card__header margin-b-md">
                          <div className="card__title">
                              Sign In
                          </div>
                      </div>
                      <div className="card__body d-flex flex-column justify-content-center">
                         <SignInForm onSuccess={onSuccess} />
                      </div>
                  </div>
              </div>
              <div className="signup__link">
                  <span>Don't have an account? <Link to={`${process.env.PUBLIC_URL}/signup`}>Sign Up</Link></span>
              </div>
          </main>
          {/* <footer className="footer">
              <div className="footer__text">
                  Copyright © Lendle 2020. All Rights reserved.
              </div>
          </footer> */}
      </div>
  );
};

export default withApollo(SignIn);
