import React from "react";
import IconsDoubleArrow from "../assets/icons/IconsDoubleArrow.svg";

const EmptyTransactions = ({type}) => (
    <div className="d-flex flex-column align-items-center align-self-center justify-self-center" style={{marginTop: "calc(var(--space-xl) * -1)"}}>
        <img src={IconsDoubleArrow} alt="" style={{width: "36px", height: "36px"}} />
        <span className="small-text-bold margin-t-md">There are no {type} yet</span>
    </div>
);

export default EmptyTransactions;