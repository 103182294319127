export const setReactSelect = (properties, data) => {
  const addLabels = {};

  for (let property of properties) {
    addLabels[property] = { value: data[property], label: data[property] };
  }
  return (data = { ...data, ...addLabels });
};

export const saveReactSelect = (properties, ...dataObjects) => {
  for (let data of dataObjects) {
    for (let property of properties) {
      let value = data[property].value;
      data[property] = value;
    }
  }
};
