import React, { useState } from "react";

// Handle our form
import { useForm } from "react-hook-form";

// Dom
import { Link } from "react-router-dom";

// Alerts
import { toast } from "react-toastify";

// HOC to get apollo client
import { useMutation } from "react-apollo";

import IconEye from "../../../assets/svg/icons-eye.svg";

// Auth queries
import {
  USER_PROFILE_QUERY,
  USER_LOAN_QUERY,
  AUTH_SIGNIN_MUTATION,
} from "../../../graphql";

import { FormPasswordInput } from "../../../components";

const SignInForm = ({ onSuccess }) => {
  const { register, handleSubmit, errors, control } = useForm();

  // Signin mutation
  const [performSignIn, { loading }] = useMutation(AUTH_SIGNIN_MUTATION);

  // Handle form submission
  const onSubmit = async (data) => {
    // Data returns empty if there are errors
    if (data === "") {
      errors.showMessages();
      return;
    }

    // Queries that we need to refetch after sign in
    const refetchQueries = [
      { query: USER_PROFILE_QUERY },
      { query: USER_LOAN_QUERY },
    ];

    // Attempt the sign in process
    try {
      await performSignIn({
        variables: { ...data },
        refetchQueries,
      });
      onSuccess();
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };

  return (
    <form noValidate="" onSubmit={handleSubmit(onSubmit)} className="form" id="signin-form">
      {loading && <div className="loadning"></div>}
      <fieldset aria-busy={loading} disabled={loading}>
         {
          Object.keys(errors).length > 0 && 
            <div className="form__message">
              {errors.email && <span>Email is required</span>}
              {errors.password && <span>Password is required</span>}
             </div>
          }
          <div className="form__row">
              <input type="email" placeholder="Your Email" name="email" className="form-control" ref={register({ required: true })} maxLength="255"/>
          </div>
          <div className="form__row">
              <FormPasswordInput
                  controlled
                  control={control}
                  rules={{
                    required: true,
                  }}
                />          
          </div>
          <div className="form__row">
            <Link className="text__forgotpwd" to={`${process.env.PUBLIC_URL}/forgot-password`}>Forgot My Password</Link>
          </div>
          <button className="btn btn-info margin-t-sm margin-b-md" type="submit">
              Sign In
          </button>
      </fieldset>
  </form>
  );
};

export default SignInForm;