import gql from "graphql-tag";

export const CARD_ADD_DISCOUNT = gql`
  mutation CARD_ADD_DISCOUNT($id: ID!, $discountType: String!) {
    addCardDiscount(id: $id, discountType: $discountType) {
      message
    }
  }
`;

// Provided an id and status it will update the loan's status
export const CARD_UPDATE_STATUS_MUTATION = gql`
  mutation CARD_UPDATE_STATUS_MUTATION($id: ID!, $status: String!) {
    updateCardStatus(id: $id, status: $status) {
      message
    }
  }
`;


export const CARD_COMMENTS = gql`
  query CARD_COMMENTS($id: ID!) {
    cardComments(id: $id) {
      id
      title
      message
      user {
        id
        name
      }
      createdAt
    }
  }
`;


export const CARD_CREATE_COMMENT = gql`
  mutation CARD_CREATE_COMMENT(
    $user: ID!
    $card: ID!
    $title: String!
    $message: String!
  ) {
    createCardComment(user: $user, card: $card, title: $title, message: $message) {
      message
    }
  }
`;

//
export const CARD_LEAD = gql`
  query CARD_LEAD($id: ID!) {
    cardLead(id: $id){
      id
      name
      phone
      email
      employerPhone
      employerJobTitle
      employerName
      monthlyIncome
      hash
      residentialAddress {
        country
        city
        street
        suite
        province
        postal
      }
      gender
      dob
    }
  }
`;
// Provided with the params this mutation will update the user's personal information
export const CARD_COMPLETE_LEAD = gql`
  mutation CARD_COMPLETE_LEAD(
    $lid: ID!
    $hid: ID!
    $email: String!
  ) {
    cardCompleteLead(
      lid: $lid
      hid: $hid
      email: $email
    ) {
      message
    }
  }
`;
//
export const CARD_REQUEST_BYID_QUERY = gql`
  query CARD_REQUEST_BYID_QUERY($id: ID!) {
    cardApplication(id: $id) {
      id
      name
      email
      phone
      status
      payments {
        id
        amount
        createdAt
        desc
      }
      residentialAddress {
        country
        city
        street
        suite
        province
        postal
      }
      documents {
        govIssuedId
        proofOfResidence
        bankStatement
        directDepositForm
        envelopeStatus
        envelopeUri
        envelopeId
      }
      user{
        id
      }
      dob
      gender
      employerName
      employerPhone
      employerJobTitle
      monthlyIncome
      cardType
      cardFee
      campaign
      discountType
    }
  }
`;

// Create a new loan request based on the params provided
export const CARD_REQUEST_MUTATION = gql`
  mutation CARD_REQUEST_MUTATION(
    $name: String!
    $email: String!
    $phone: String!
    $street: String!
    $city: String!
    $suite: String
    $postal: String!
    $province: String!
    $country: String!
    $bankStatement: Upload
    $proofOfResidence: Upload
    $govIssuedId: Upload
    $directDepositForm: Upload
    $dob: String!
    $gender: String!
    $employerName: String!
    $employerPhone: String!
    $employerJobTitle: String!
    $monthlyIncome: String!
    $cardType: String!
    $cardFee: String!
    $cardLimit: String!
  ) {
    requestCard(
        name: $name
        email: $email
        phone: $phone
        street: $street
        city: $city
        suite: $suite
        postal: $postal
        province: $province
        country: $country
        bankStatement: $bankStatement
        proofOfResidence: $proofOfResidence
        govIssuedId: $govIssuedId
        directDepositForm: $directDepositForm
        dob: $dob
        gender: $gender
        employerName: $employerName
        employerPhone: $employerPhone
        employerJobTitle: $employerJobTitle
        monthlyIncome: $monthlyIncome
        cardType: $cardType
        cardFee: $cardFee
        cardLimit: $cardLimit
    ) {
      id
    }
  }
`;

export const CARD_ADD_PAYMENT = gql`
  mutation CARD_ADD_PAYMENT($cardId: ID!, $userId: ID!, $amount: String!, $desc: String) {
    addCardPayment(cardId: $cardId, userId: $userId, amount: $amount, desc: $desc) {
      message
    }
  }
`;