import React from 'react';

const useModal = () => {
    
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const openModal = () => {
      setIsOpen(true);
    }

    const closeModal = () => {
      setIsOpen(false);
    }

    return [
        modalIsOpen,
        closeModal,
        openModal
    ]
    
  }
   
 
export default useModal;