import React from "react";
import Select from "react-select";

const customStyles = {
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: 'rgba(var(--light-gray), 1)',
        }
    },
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        height: "45px",
        width: "100%",
        flex: 1,
        border: "solid 1px rgba(182, 195, 217, 0.25)",
        marginTop: "var(--space-sm)",
        marginBottom: "var(--space-sm)",
        borderRadius: "5px",
        color: "rgba(var(--black), 1)",
        paddingLeft: "5px",
        paddingTop: "2px",
        fontSize: "var(--text-xs)",
        transition: "background-color 0.5s, border-color 0.5s",
        textAlign: "left"
        // You can also use state.isFocused to conditionally style based on the focus state
    })
}

const TableColumnSearch = ({ onChange, options }) => {
  return (
    <Select
        options={options}
        name="tableColumn"
        onChange={onChange}
        classNamePrefix="react-select"
        styles={customStyles}
        placeholder="All Columns"
    />
  );
};

export default TableColumnSearch;
