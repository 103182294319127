import gql from 'graphql-tag';

// Provided a user id this mutation will resend the user email verification
export const AUTH_EMAIL_VERIFICATION = gql`
  mutation AUTH_EMAIL_VERIFICATION($id: ID!) {
    resendVerification(id: $id) {
      message
    }
  }
`;

// Resend the email verification
export const AUTH_FORGOT_PASSWORD_REQUEST = gql`
  mutation AUTH_FORGOT_PASSWORD_REQUEST($email: String!) {
    requestReset(email: $email) {
      message
    }
  }
`;

// Resend the email verification
export const AUTH_RESET_PASSWORD = gql`
  mutation AUTH_RESET_PASSWORD($resetToken: String!, $password: String!, $confirmPassword: String!) {
    resetPassword(resetToken: $resetToken, password: $password, confirmPassword: $confirmPassword) {
      message
    }
  }
`;

// Sign in the user
export const AUTH_SIGNIN_MUTATION = gql`
  mutation AUTH_SIGNIN_MUTATION(
    $email: String!
    $password: String!
  ) {
    signin(email: $email, password: $password) {
      id
      email
      name
    }
  }
`;

// Sign up a new user
export const AUTH_SIGNUP_MUTATION = gql`
  mutation AUTH_SIGNUP_MUTATION(
    $email: String!
    $name: String!
    $password: String!
    $phone: String!
    $dob: String!
  ) {
    signup(
      email: $email
      name: $name
      password: $password
      phone: $phone
      dob: $dob
    ) {
      id
      email
      name
      phone
      dob
    }
  }
`;

// Sign out the user
export const AUTH_SIGNOUT_MUTATION = gql`
  mutation AUTH_SIGNOUT_MUTATION {
    signout {
      message
    }
  }
`;

// Provided the correct email and hash it will verify the user
export const AUTH_VERIFY_USER_MUTATION = gql`
  mutation AUTH_VERIFY_USER_MUTATION(
    $email: String!
    $hash: String!
  ) {
    verifyEmail(email: $email, hash: $hash) {
      message
    }
  }
`;
