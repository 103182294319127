import React from "react";
import { useDropzone } from "react-dropzone";

const LoanWizardZone = ({title, id, icon, onDropAccepted, maxSize, minSize, accept}) => {
    const {getRootProps, getInputProps, isDragActive, isDragReject, fileRejections} = useDropzone({onDropAccepted, maxSize, minSize, accept})
    const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].size > maxSize;
    return (
        <div className="loan-wizard__zone" {...getRootProps({ key: id })} style={{borderColor: isDragActive ? "rgb(0, 84, 233)" : "rgba(182, 195, 217, 0.25)" }}>
            <input {...getInputProps({ name: "frontsideInput "})} />
            <div className="loan-wizard__icon">
                <img src={icon} alt="card" />
            </div>
                <h4>{title}</h4>
                { isDragActive && !isDragReject && <small>Drag and drop documents or <a key={id} href="#">browse files</a></small> }
                { !isDragActive && <small>Drag and drop documents or <a href="#">browse files</a></small> }
                {isDragReject && <small style={{color: "red"}}>File type not accepted, sorry!</small>}
                {isFileTooLarge && (
                    <small className="text-danger mt-2" style={{color: "red"}}>
                        File is too large.
                    </small>
                )}
        </div>
        );
    }
export default LoanWizardZone;