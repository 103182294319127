import Details from "./Details";
import Upload from "./Upload";
import Authorization from "./Authorization";
import Complete from "./Complete";

export {
    Details,
    Upload,
    Authorization,
    Complete
}