import React from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({onDropAccepted, maxSize, minSize, accept}) => {
    const {getRootProps, getInputProps, isDragActive, isDragReject, fileRejections} = useDropzone({onDrop: onDropAccepted, maxSize, minSize, accept})
    const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].size > maxSize;
    return (
    <div className="dropzone" {...getRootProps()} style={{borderColor: isDragActive ? "rgb(0, 84, 233)" : "rgba(182, 195, 217, 0.25)" }}>
        <input {...getInputProps()} name="frontsize" />
        <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <g fill="#b5c2d9">
                    <g>
                        <path d="M30 20c.552 0 1 .448 1 1v8h8c.552 0 1 .448 1 1s-.448 1-1 1h-8v8c0 .552-.448 1-1 1s-1-.448-1-1v-8.001L21 31c-.552 0-1-.448-1-1s.448-1 1-1l8-.001V21c0-.552.448-1 1-1z" transform="translate(-310 -745) translate(290 725)"/>
                    </g>
                </g>
            </g>
        </svg>
        { isDragActive && !isDragReject &&  "Drag and drop photo here" }
        { !isDragActive && "Upload file here" }
        {isDragReject && <small style={{color: "red"}}>File type not accepted, sorry!</small>}
        {isFileTooLarge && (
            <small className="text-danger mt-2" style={{color: "red"}}>
                File is too large.
            </small>
        )}
    </div>
);
    }
export default Dropzone;