import React, { useState } from "react";

// Essential imports
import moment from "moment";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useMutation } from "react-apollo";

// Auth components we need
import {
  ModalBox,
  FormGenderSelect,
  FormProvinceSelect,
  FormHDYHAU
} from "../../../components";

// Auth and User queries
import {
  USER_UPDATE_INFORMATION_MUTATION,
  USER_PROFILE_QUERY
} from "../../../graphql";

import { useModal, useUser } from "../../../hooks";

const SignUpForm = ({ me, onSuccess }) => {
  const dob = moment(me.dob, "DD-MM-YYYY");
  const [fetchResult, setFetchResult] = useState({})
  // Initialize our form hooks
  
  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
  } = useForm({
    defaultValues: {
      name: me.name,
      email: me.email,
      phone: me.phone,
      dob: dob.format("LL")
    }
  });
  // Signup muttion
  const [runSignup, { loading }] = useMutation(USER_UPDATE_INFORMATION_MUTATION);
  const [modalIsOpen, closeModal, openModal] = useModal();

  const onSubmit = async (data) => {

    if (data === "") {
      errors.showMessages();
      return;
    }
    // Deconstruct some form data
    const { city, province, street, postal, suite, gender, source } = data;

    // Flush some important queries
    const refetchQueries = [
      { query: USER_PROFILE_QUERY,
        variables: {
        id: me.id,
      },
     },
    ];

    // Query variables
    const variables = {
      id: me.id,
      city,
      province: province.value, 
      street,
      postal,
      gender: gender.value.toUpperCase(),
      suite,
      source: source.value
    };

    try {
      // Attempt the sign up mutation
      const signup = await runSignup({
        variables,
        refetchQueries,
      });

      setFetchResult(signup);
      // Clear form
      reset();
      // Run our post signup function
    }catch (error){
      setTimeout(() => {
        toast.error(error.message.replace("GraphQL error: ", ""));
      }, 200);
    }

  };

  return (
    <form className="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      {loading && 
                <div className="loading-overlay">
                    <div className="loading"></div>
                </div>
            }
      {/* <ModalBox 
        modalIsOpen={modalIsOpen}
        openModal={openModal}
        closeModal={closeModal}
        title="Congratulations"
      >
        <p>We have all that we need from you<br /> Click the link below to get started with Lendle.</p>        
        <button onClick={() => onSuccess(fetchResult)} className="btn btn-info margin-t-sm margin-b-sm"  style={{textDecoration: "none", width: "100%", marginBottom: "1em"}} >Complete Signup</button>
      </ModalBox> */}
      <fieldset aria-busy={loading} disabled={loading}>
        {
          Object.keys(errors).length > 0 && 
            <div className="form__message">
                {errors.name && <span>Name is required</span>}
                {errors.email && (<span>{errors.email.message !== "" ? errors.email.message : "Email is required"}</span> )}
                {errors.phone && <span>Invalid Phone Number</span>}
                {errors.city && <span>City is required</span>}
                {errors.province && <span>Province is required</span>}
                {errors.street && <span>Street is required</span>}
                {errors.suite && <span>Suite is required</span>}
                {errors.postal && <span>Postal is required</span>}
                {errors.gender && <span>Gender is required</span>}
            </div>
        }
        <div className="form__group">
            <div className="form__col">
                <input type="text" placeholder="Name" name="name" className="form-control" ref={register}/>
            </div>
            <div className="form__col">
                <input type="text" placeholder="Phone Number" name="phone" className="form-control" ref={register}/> 
            </div>
        </div>
        <div className="form__group">
            <div className="form__col">
                <input type="email" placeholder="Your Email" name="email" className="form-control" ref={register} disabled />              
            </div>
            <div className="form__col">
                <input type="phone" placeholder="Date of birth" name="dob" className="form-control" ref={register}/>              
            </div>
        </div>
        <div className="form__group">
            <div className="form__col">
                <input type="text" placeholder="City" name="city" className="form-control" ref={register({required: true })} />
            </div>
            <div className="form__col form__select">
               <FormProvinceSelect
                  control={control}
                  name="province"
                  rules={{
                    required: true,
                    maxLength: 10,
                    validate: (select) => select.value !== undefined,
                  }}
                />
            </div>
        </div>
        <div className="form__group">
            <div className="form__col">
                <input type="text" placeholder="Street" name="street" className="form-control" ref={register({required: true })} />
            </div>
            <div className="form__col">
                <input type="text" placeholder="Suite" name="suite" className="form-control" ref={register}  /> 
            </div>
        </div>
        <div className="form__group">
            <div className="form__col">
                <input
                  className="form-control"
                  name="postal"
                  placeholder="Postal Code"
                  ref={register({
                    required: true,
                    validate: (value) => {
                      var regex = new RegExp(
                        /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                      );
                      return regex.test(value) || "Invalid postal code.";
                    },
                  })}
                  type="text"
                />
            </div>
            <div className="form__col">
                  <input type="text" placeholder="Address Line 2" />
            </div>
        </div>
        <div className="form__group">
            <div className="form__col form__select">
              <FormGenderSelect
                control={control}
                rules={{
                  required: true,
                  validate: (select) => select.value !== undefined,
                }}
              />
            </div>
            <div className="form__col form__select">
                <FormHDYHAU control={control} />
            </div>
        </div>
        <button id="auth-form-btn" style={{textDecoration: "none", width: "100%"}} disabled={Object.keys(errors).length > 0} className="btn btn-info margin-t-sm margin-b-md">Finish Sign Up</button>
      </fieldset>
    </form>
  );
};

export default SignUpForm;
