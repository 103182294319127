import React from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

const ReactDatePicker = ({ control, rules, name, ...rest }) => {
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ onChange, value }) => (
        <DatePicker
          className="form-control digits"
          onChange={onChange}
          selected={value}
          minDate={new Date()}
          {...rest}
        />
      )}
    />
  );
};

ReactDatePicker.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default ReactDatePicker;
