import React, { useState, useEffect } from "react";
import { TableColumnSelect } from "../components";
import moment from "moment";
import {useWindowResize} from "../hooks";
import getActivity from "../services/activities";

const openStyleDesktop = {
    width: "180px",
    backgroundPositionX: "10px",
    backgroundPositionY: "center",
    color: "black",
    paddingLeft: "40px",
    paddingRight: "20px"
}

const openStyleMobile = {
    width: "100%",
    backgroundPositionX: "10px",
    backgroundPositionY: "center",
    color: "black",
    paddingLeft: "40px",
    paddingRight: "20px"
}

const TableHeaderFilter = React.memo(({ columns, tableTitle, tableData, handleSetFilteredData, handleSetSearchInput}) => {
    
    const [columnSearch, setColumnSearch] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const size = useWindowResize();
    const [isDesktop, setIsDesktop] = useState(false);

    const handleChange = event => {
        const val = event.target.value;
        setSearchInput(val);
        handleSetSearchInput(val);
        globalSearch();
    };

    useEffect(() => {
        if( size.width > 700 ){
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }
    }, [size]);

    const globalSearch = () => {
        let filterData = tableData.filter(value => {
            if(columnSearch) {
                let v = value[columnSearch];
                if(v === undefined || v === null || v === ""){
                    return [];
                }
                if(columnSearch === "createdAt"){
                    v = moment(v).format("LL");
                }
                return v
                .toString()
                .toLowerCase()
                .includes(String(searchInput.toLowerCase()));
            }
            const adj = value['adj'] ? value['adj'].name.toLowerCase().includes(searchInput.toLowerCase()) : false;
            const amount = value['loanAmount'] ? value['loanAmount'].toString().toLowerCase().includes(searchInput.toLowerCase()) : false;
            const duration = value['paymentCycleDuration'] ? value['paymentCycleDuration'].toString().toLowerCase().includes(searchInput.toLowerCase()) : false;
            const status = value['status'] ? value['status'].toLowerCase().includes(searchInput.toLowerCase()) : false;
            const id = value['id'] ? value['id'].toLowerCase().includes(searchInput.toLowerCase()) : false;
            const ip = value['ip'] ? value['ip'].toLowerCase().includes(searchInput.toLowerCase()) : false;
            const type = value['type'] ? getActivity(value['type']).title.toLowerCase().includes(searchInput.toLowerCase()) : false;

            const message = value['message'] ? getActivity(value['message']).title.toLowerCase().includes(searchInput.toLowerCase()) : false;
            const title = value['title'] ? getActivity(value['title']).title.toLowerCase().includes(searchInput.toLowerCase()) : false;

            
            return (
                id ||
                adj ||
                duration || 
                status || 
                ip ||
                type ||
                message || 
                title ||
                value['createdAt'].toLowerCase().includes(searchInput.toLowerCase()) ||
                amount
            );
        })
        handleSetFilteredData(filterData);
    }

    return (
        <div className="table-header">
            <div className="table-header__title">
                {tableTitle}
            </div>
            <div className="table-header__filters">
                <input type="text" className="table-search-input" style={searchInput === '' ? {} : isDesktop ? openStyleDesktop : openStyleMobile} onChange={handleChange} value={searchInput}  />
                <div className="table-filter-select">
                    <TableColumnSelect options={columns} onChange={(column) =>{
                         setColumnSearch(column.value);
                         globalSearch();
                    }}  />
                </div>
            </div>
        </div>
    )
});

export default TableHeaderFilter;