import React from "react";
import { Link } from "react-router-dom";

const WelcomeCard = ({hasCardStyle = true, hasContainer = false}) => (
    <div className={`welcome-card ${hasContainer ? "container" : ""} ${hasCardStyle === false ? "" : "card padding-lg card--rounded card--shadow"}`}>
        <div className="card__header">
            <div className="card__title text-center">
                Welcome to Lendle, to begin start your application.
            </div>
        </div>
        <div className="card__body d-flex flex-column justify-content-center">
            <p className="welcome-card__text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <div className="welcome-card__buttons margin-t-sm">
                <Link to={`${process.env.PUBLIC_URL}/card/apply`} className="btn btn-light margin-t-md">Credit Card Application</Link>
                <Link to={`${process.env.PUBLIC_URL}/dashboard/request`} className="btn btn-primary margin-t-sm margin-b-md">Loan Application</Link>
            </div>
        </div>
    </div>
);

export default WelcomeCard;