import React from "react";
import IconsDocument from "../assets/icons/iconsDocument.svg";
import SvgCardBg from "../assets/svg/card-bg.svg";
import SvgVisa from "../assets/svg/visa.svg";

const VisaCard = ({empty = false, amount = 0, lastDigits = "0000", onClick}) => (
    <div className={`visacard ${empty && "empty"}`}  onClick={() => empty ? onClick() : null}>
          {
              !empty ? 
              (
                <>
                    <div className="visacard__topleft">
                    <div className="visacard__title">Visa Card</div>
                    <div className="visacard__number">****&nbsp;&nbsp;{lastDigits}</div>
                    </div>
                    <div className="visacard__topright">
                        <div className="visacard__amount">{amount}</div>
                    </div>
                    <div className="visacard__bottomleft">
                        <img src={SvgCardBg} />
                    </div>
                    <div className="visacard__bottomright">
                        <img src={SvgVisa} />
                    </div>
                </>
              ) : 
              (
                   <div className="visacard__empty">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 20 20">
                            <g fill="none" fillRule="evenodd">
                                <g fill="#b5c2d9">
                                    <g>
                                        <path d="M30 20c.552 0 1 .448 1 1v8h8c.552 0 1 .448 1 1s-.448 1-1 1h-8v8c0 .552-.448 1-1 1s-1-.448-1-1v-8.001L21 31c-.552 0-1-.448-1-1s.448-1 1-1l8-.001V21c0-.552.448-1 1-1z" transform="translate(-310 -745) translate(290 725)"/>
                                    </g>
                                </g>
                            </g>
                        </svg><span>Add Mastercard</span>
                    </div>
              )
          }
       
    </div>
);

export default VisaCard