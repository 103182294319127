import React from "react";
import moment from "moment";

import getActivity from "../services/activities";

const ActivityTableRow = ({createdAt, type, ip}) => (
    <tr>
        <td className="table-date">{moment(createdAt).format("LLL")}</td>
        <td>{getActivity(type).title}</td>
        <td>0.0.0.0</td>
    </tr>
)

export default ActivityTableRow;