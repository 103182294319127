import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer,
} from 'recharts';

export default class StackedBarChart extends PureComponent {
  
  render() {
    const tickFormatter = (tick) => `$${tick}`;
    const { data } = this.props.data;
    
    return (
        <ResponsiveContainer width="99%">
            <BarChart
                data={data}
                barGap={0}
            >
                <XAxis tickLine={false} tick={{ fontSize: "0.8em", fill: "#a1aec5" }} dataKey="name" axisLine={false}/>
                <YAxis tickLine={false} tickFormatter={tickFormatter}  tick={{ fontSize: "0.8em", fill: "#a1aec5" }} axisLine={false} />
                <Tooltip cursor={{fill: 'transparent'}}/>
                <Bar animationEasing="linear" dataKey="amount" stackId="a" fill="#0054e9" stroke="white" strokeWidth="4" barSize={14} radius={[10, 10, 10, 10]} />
                <Bar animationEasing="linear" dataKey="outstanding" stackId="a" fill="#963de7" stroke="white" strokeWidth="4" barSize={14} radius={[10, 10, 10, 10]}  />
                <Bar animationEasing="linear" dataKey="reusable" stackId="a" fill="#83d2ff" stroke="white" strokeWidth="4" barSize={14} radius={[10, 10, 10, 10]}  />
                <Bar animationEasing="linear" dataKey="filler" stackId="a" fill="#ecf2fd" stroke="white" strokeWidth="4" barSize={14} radius={[10, 10, 10, 10]}  />

            </BarChart>
        </ResponsiveContainer>
    );
  }
}
