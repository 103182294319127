import {
 SIDEBAR_TOGGLE,
 SIDEBAR_CLOSE,
 FIRST_ANIMATION
} from "../actions/constants";

const initialState ={
    hideSidebar: true,
    firstanimation: false
}

const uiReducer = (state = initialState, action) => {
    
    switch(action.type){
        case SIDEBAR_TOGGLE: 
            return {
                ...state,
                hideSidebar: !state.hideSidebar
            }
        case SIDEBAR_CLOSE: 
            return {
                ...state,
                hideSidebar: true
            }
        case FIRST_ANIMATION: 
            return {
                ...state,
                firstanimation: true
            }
        default: 
            return state;
    }
}

export default uiReducer;
