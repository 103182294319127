import React from "react";
import { useQuery } from "react-apollo";
import moment from "moment";
import { USER_NOTIFICATIONS_QUERY } from "../graphql";

const NotificationDropdown = () => {
    
    const [isDropDownOpen, setDropDownOpen] = React.useState(false);

    const { data, loading } = useQuery(
        USER_NOTIFICATIONS_QUERY,
        {
          variables: {
            returnOnlyNew: true
          },
          fetchPolicy: 'no-cache'
        }
      );
    

    return(
        <div className="header__item" onClick={() => setDropDownOpen(!isDropDownOpen)} >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
                    <g fillRule="evenodd">
                        <g>
                            <g>
                                <g transform="translate(-216 -53) translate(216 45) translate(0 8)">
                                    <path d="M8 1c3.314 0 6 2.686 6 6v7H2V7c0-3.314 2.686-6 6-6z"/>
                                    <rect width="2" height="3" x="7" rx="1"/>
                                    <path d="M2 12h12l1.6 1.2c.252.189.4.485.4.8v.25c0 .414-.336.75-.75.75H.75c-.414 0-.75-.336-.75-.75V14c0-.315.148-.611.4-.8L2 12zM10 16c0 1.105-.895 2-2 2s-2-.895-2-2h4z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <div className="dropdown-menu" style={{
                    width: !loading && data && data.userNotifications && data.userNotifications.length > 0 ? "290px" : "180px",
                    transform: isDropDownOpen ? "translateX(-145px)" : "scaleY(0)",
                    opacity: isDropDownOpen ? 1 : 0
                }}>
                {
                    loading ? <div className="loading" /> : 
                    data && data.userNotifications && data.userNotifications.length > 0 ? data.userNotifications.map((notification, item) => (
                        <div className="dropdown-menu__item">
                            <div className="dropdown-menu__icon" style={{marginRight: 0}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#0054e9" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" style={{marginRight: "0.8rem"}}>
                                    <circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line>
                                    <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                </svg>
                            </div>
                            <div className="dropdown-menu__link flex-full">
                                <div className="d-flex justify-space-between">
                                    <span style={{fontWeight: "bold"}}>{notification.title}</span>
                                    <span style={{color: "gray"}}>{moment(notification.createdAt).fromNow()}</span>
                                </div>
                                <div className="d-flex flex-full padding-y-xs">
                                    {notification.message}
                                </div>
                            <div className="dropdown-menu__divider" />
                            </div>
                        </div>
                    )) : 
                    <div className="dropdown-menu__item">
                        <div className="dropdown-menu__link flex-full">
                        <div className="text-center">
                            No Notifications Yet...
                        </div>
                    </div>
                    </div>
                }
            </div>
        </div> 
    );
};

export default NotificationDropdown;