import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const customStyles = {
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: 'rgba(var(--light-gray), 1)',
        }
    },
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        height: "45px",
        width: "100%",
        border: "solid 1px rgba(182, 195, 217, 0.25)",
        marginTop: "var(--space-sm)",
        marginBottom: "var(--space-sm)",
        borderRadius: "5px",
        color: "rgba(var(--black), 1)",
        paddingLeft: "5px",
        paddingTop: "2px",
        fontSize: "var(--text-xs)",
        transition: "background-color 0.5s, border-color 0.5s",
        // You can also use state.isFocused to conditionally style based on the focus state
    })
}

const FormCardLimitSelect = ({ control, rules }) => {
  const options = [
    {
      label: "$300",
      value: "300",
    },
    {
      label: "$400",
      value: "400",
    },
    {
      label: "$500",
      value: "500",
    },
    {
      label: "$600",
      value: "600",
    },
    {
      label: "$700",
      value: "700",
    },
    {
      label: "$800",
      value: "800",
    },
    {
      label: "$900",
      value: "900",
    },
    {
      label: "$1000",
      value: "1000",
    },
  ];
  return (
    <Controller
      as={Select}
      control={control}
      name="cardTest"
      options={options}
      placeholder="Credit Card Limit"
      rules={{
        required: true,
        maxLength: 10,
      }}
      classNamePrefix="react-select"
      styles={customStyles}
    />
  );
};

export default FormCardLimitSelect;
