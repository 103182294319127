import React from "react";
import CountUp from 'react-countup';

const MetricsCardBox = ({title, subtitle, amount, theme, icon}) => (
    <div className="metrics__box">
        <div className={`metrics__icon ${theme} margin-x-md`}>
            { icon}
        </div>
        <div className="metrics__details">
            <div className="metrics__title">
                {title}
            </div>
            <div className="metrics__subtitle">
                {subtitle}
            </div>
        </div>
        <div className="metrics__amount"><CountUp start={0} duration={2} end={amount} useEasing/></div>
    </div>
);

export default MetricsCardBox;