import React from "react";
import { Link } from "react-router-dom";

import logoSmall from "../assets/images/logo.png";
import logoMedium from "../assets/images/logo@2x.png";
import logoLarge from "../assets/images/logo@3x.png";
import IconsMenu from "../assets/icons/menu.svg";

const WizardHeader = ({children}) => {

    return (
        <header className="header">
            <div className="header__menu">
                <Link to={`${process.env.PUBLIC_URL}/dashboard/overview`}>
                    <img 
                        className="header__item"
                        srcSet={`
                            ${logoSmall} 1x,
                            ${logoMedium} 2x,
                            ${logoLarge} 3x   
                            `}
                        alt="Lendle"
                    />
                </Link>
            </div>
            <div className="header__widgets">
               {children}      
            </div>
        </header>
    )
}

export default WizardHeader;