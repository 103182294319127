import React from "react";
import IconsStatement from "../assets/icons/icons-statement.svg";
import IconsFund from "../assets/icons/icons-missed-arrow.svg"

import { EmptyTransactions } from "../components";

const STATEMENTS = [
    {
        title: "Fund",
        date: "20.08.2020",
        amount: "+20%",
        status: "fund"
    },
    {
        title: "Statement Month",
        date: "20.08.2020",
        amount: "+20%",
        status: "statement"
    },{
        title: "Statement Month",
        date: "20.08.2020",
        amount: "+20%",
        status: "statement"
    },
    {
        title: "Fund",
        date: "20.08.2020",
        amount: "+20%",
        status: "fund"
    }
]

const CardRowStatusStatement = () => (
    <div className="score-activity__icon score--positive">
        <img src={IconsStatement} alt="up arrow" />
    </div>
)

const CardRowStatusFund = () => (
    <div className="score-activity__icon score--negative">
        <img src={IconsFund} alt="down arrow" />
    </div>
)

const CardStatementsRow = ({ title, date, amount, status}) => (
    <div className="score-activity__row">
        {status === "statement" && <CardRowStatusStatement />}
        {status === "fund" && <CardRowStatusFund />}
        <div className="score-activity__details">
            <div className="score-activity__title">
               {title}
            </div>
            <div className="score-activity__subtitle">
              {date}
            </div>
        </div>
        <div className="score-activity__amount">{amount}</div>
    </div>
);

const CardStatementsCard = () => {

    return <EmptyTransactions type="transactions" />
    // return (
    //     <div className="score-activity margin-b-md">
    //         {
    //             STATEMENTS.map((statement, index) => <CardStatementsRow key={index} {...statement} />)
    //         }
    //     </div>
    // )
}

export default CardStatementsCard;