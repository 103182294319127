import React from "react";
import currency from "currency.js";
import { useQuery } from "react-apollo";
import MetricsCardBox from "./MetricsCardBox";
import StackedBarChart from "./StackedBarChart";
import IconsLoan from "../assets/svg/icons-loan.svg";
import IconsOustanding from "../assets/svg/icons-outstanding.svg";
import IconsReusable from "../assets/svg/icons-reusable.svg";

import metricsCalc from "../services/metricsCalc";
import { METRICS_FUNDS_HISTORY } from "../graphql";


const MetricsCard = ({me, loan, metrics}) => {    
    const { loanAmount, loanBalance, loansTotalAmountPaid } = metricsCalc(loan);
        
    const {loading, data, error} = useQuery(METRICS_FUNDS_HISTORY, {
        variables: {
            id: me.id
        },
        fetchPolicy: "network-only",
    });

    if(loading){
        return "";
    }

    return (
    <div className="metrics-card card card--rounded card--shadow">
        <div className="card__body">
           <div className="metrics">
               <MetricsCardBox title="Loan" subtitle="Amount" amount={loanAmount ? loanAmount : 0} theme="metrics--blue" icon={<img src={IconsLoan} alt="up arrow" />} />
               <MetricsCardBox title="Outstanding" subtitle="Balance" amount={loanBalance ? loanBalance : 0} theme="metrics--purple" icon={<img src={IconsOustanding} alt="up arrow" />} />
               <MetricsCardBox title="Reusable" subtitle="Balance" amount={loansTotalAmountPaid ? loansTotalAmountPaid : 0} theme="metrics--cyan" icon={<img src={IconsReusable} alt="up arrow" />} />
           </div>
           <div className="chart">
               <StackedBarChart data={JSON.parse(data.metricsFundsHistory.data)} />
           </div>
        </div>
    </div>
    )
};

export default MetricsCard;