import React from 'react';

const FormPasswordIndicator = ({ score }) => {
  const getPassStrengthColor = (score) => {
    if (score === 2) {
      return '#57B8FF';
    }
    if (score === 3) {
      return '#57B8FF';
    }
    if (score === 4) {
      return '#2FBF71';
    }
    return 'red';
  };
  return (
    <p
      className="pull-right"
      style={{
        display: 'inline-block',
        color: getPassStrengthColor(score),
      }}>
      {score === 0 && 'too short'}
      {score === 1 && 'weak'}
      {score === 2 && 'okay'}
      {score === 3 && 'good'}
      {score === 4 && 'strong'}
    </p>
  );
};

export default FormPasswordIndicator;
