import React from "react";
import { useMutation } from "react-apollo";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";

import IconsCamera from "../../assets/icons/iconsCamera.svg"
import IconsCamera2 from "../../assets/icons/icons-camera.svg"

import {
    FormPhoneInput,
    FormGenderSelect,
    FormDobSelect,
    FormProvinceSelect
} from "../../components";

import {
    USER_PROFILE_QUERY,
    USER_UPDATE_INFORMATION_MUTATION,
  } from "../../graphql";

import { useUser } from "../../hooks";

const EditProfileInfo = () => {
    const me = useUser();
    const fname = me && me.name && me.name.split(" ")[0] ? me.name.split(" ")[0] : '';
    const lname = me && me.name && me.name.split(" ")[1] ? me.name.split(" ")[1] : '';
    const [dobDay, dobMonth, dobYear] = me.dob.split("-");

    const [
        updateUser,
        { loading: profileFormLoading, error: profileUpdateError },
      ] = useMutation(USER_UPDATE_INFORMATION_MUTATION);
    

    const {
        register,
        handleSubmit,
        errors,
        control,
    } = useForm({
        defaultValues: {
            fname,
            lname,
            phone: me.phone,
            gender: {
                value: me.gender,
                label: me.gender.charAt(0).toUpperCase() + me.gender.toLowerCase().slice(1)
            },
            dobDay: {
                value: dobDay,
                label: dobDay
            }, 
            dobMonth: {
                value: dobMonth,
                label: dobMonth
            }, 
            dobYear: {
                value: dobYear,
                label: dobYear
            },
            street: me.residentialAddress ? me.residentialAddress.street : null,
            city: me.residentialAddress ? me.residentialAddress.city : null,
            suite: me.residentialAddress ? me.residentialAddress.suite : null,
            postal: me.residentialAddress ? me.residentialAddress.postal : null,
            province: me.residentialAddress ? { value: me.residentialAddress.province, label: me.residentialAddress.province } : null,
        }
    });

    const onSubmit = async (data) => {

        if (data === "") {
            errors.showMessages();
            return;
        }
        const dob = `${data.dobDay.label}-${data.dobMonth.label}-${data.dobYear.label}`;

        const refetchList = [
            {
              query: USER_PROFILE_QUERY,
              fetchPolicy: "no-cache",
              variables: {
                id: me.id,
              },
            },
          ];

          try {
            await updateUser({
                variables: {
                  ...data,
                  id: me.id,
                  name: data.fname + " " + data.lname,
                  dob,
                  country: "canada",
                  gender: data.gender.value.toUpperCase(),
                  phone: data.phone,
                  province: data.province.value
                },
                refetchQueries: refetchList,
              });
              toast.success("Successfully Updated Your Profile");

          } catch (error) {
            toast.error(error.message.replace("GraphQL error: ", ""));
          }
    }
    return (
        <>
            {
                profileFormLoading && (
                        <div className="loading"></div>
                )
            }
        <form className="form profile-box card padding-md card--rounded card--shadow" style={{flexWrap: "wrap"}} noValidate onSubmit={handleSubmit(onSubmit)}>
        {
          Object.keys(errors).length > 0 && <div className="form__message" style={{flexBasis: "100%"}}>
              {errors.fname && <span>First Name is required</span>}
                {errors.lname && <span>Last Name is required</span>}
                {errors.email && (<span>{errors.email.message !== "" ? errors.email.message : "Email is required"}</span> )}
                {errors.phone && <span>Invalid Phone Number</span>}
              {(errors.dobDay || errors.dobMonth || errors.dobYear) && (<span>Your date of birth is required</span>)}
       
                {errors.city && <span>City is required</span>}
                {errors.province && <span>Province is required</span>}
                {errors.street && <span>Street is required</span>}
                {errors.suite && <span>Suite is required</span>}
                {errors.postal && <span>Postal is required</span>}
                {errors.gender && <span>Gender is required</span>}
        </div>
        }
        <div className="card margin-desktop-r-sm flex-full">
            <div className="card__title">Person Information</div>
                <div className="card__content">
                    <div className="form__picture">
                        <button className="btn btn-info-outline change-photo-btn">
                            <img src={IconsCamera} style={{width: "15px", height: "13px", marginRight: "5px"}} alt="" />
                            Change Photo
                        </button>
                        <div className="profile-widget">
                            <div className="profile-widget__content">
                            <img src={IconsCamera2} alt="" />
                            <span>Change Photo</span>
                            </div>
                        </div>
                    <div className="form__nested">
                        <div className="form__row">
                            <input type="text" disabled={profileFormLoading} placeholder="Firstname" name="fname" className="form-control" ref={register({ required: true })} />
                        </div>
                        <div className="form__row">
                            <input type="text" disabled={profileFormLoading} placeholder="Lastname" name="lname" className="form-control" ref={register({ required: true })} />
                        </div>
                    </div>
                </div>
                <div className="form__row">
                    <FormPhoneInput
                        control={control}
                        rules={{
                        required: true,
                        validate: (value) => isValidPhoneNumber(value),
                        }}
                    />   
                </div>
                <div className="form__row form__select">
                    <FormGenderSelect
                        control={control}
                        rules={{
                        required: true,
                        validate: (select) => select.value !== undefined,
                        }}
                    />
                </div>
                <div className="form__dob form__select">
                    <FormDobSelect control={control} rules={{ required: true }} />    
                </div>
                </div>
            </div>
            <div className="card margin-desktop-l-sm overflow-visible flex-full">
                <div className="card__title">Address</div>
                <div className="card__content">
                    <div className="form__row">
                        <input type="text" disabled={profileFormLoading} placeholder="Street" name="street" className="form-control" ref={register({ required: true })} />
                    </div>
                    <div className="form__row">
                        <input type="text" disabled={profileFormLoading} placeholder="City" name="city" className="form-control" ref={register({ required: true })} />
                    </div>
                    <div className="form__row form__select">
                        <FormProvinceSelect
                            control={control}
                            name="province"
                            rules={{
                                required: true,
                                maxLength: 10,
                                validate: (select) => select.value !== undefined,
                            }}
                        />
                    </div>
                    <div className="form__group">
                        <div className="form__col">
                            <input type="text" disabled={profileFormLoading} placeholder="Suite" name="suite" ref={register} className="form-control" />
                        </div>
                        <div className="form__col">
                            <input type="text" disabled={profileFormLoading} placeholder="Postal Code" name="postal" className="form-control" ref={register({ required: true })} />
                        </div>
                    </div>
                    <div className="form__row">
                        <button id="auth-form-btn" style={{textDecoration: "none", width: "100%"}} className="btn btn-info margin-t-sm margin-b-md">Update Profile</button>
                    </div>
                </div>
            </div>
        </form>
        </>
    )
    
}
export default EditProfileInfo;