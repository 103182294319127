import gql from 'graphql-tag';

export const CREATE_NOTIFICATION = gql`
  mutation CREATE_NOTIFICATION(
    $user: ID!
    $title: String!
    $message: String!
    $brand: String!
    $icon: String!
    $category: String!
  ) {
    createNotification(
    user: $user
    title: $title
    message: $message
    brand: $brand
    icon: $icon
    category: $category
    ){
      message
    }
  }
`;


export const CREATE_LOAN_NOTIFICATION = gql`
  mutation CREATE_LOAN_NOTIFICATION (
    $user: ID!
    $title: String!
    $message: String!
    $brand: String!
    $icon: String!
    $category: String!

    $loan: ID
  ) {
    createNotification(
    user: $user
    title: $title
    message: $message
    brand: $brand
    icon: $icon
    category: $category

    loan: $loan
    ){
      message
    }
  }
`;
