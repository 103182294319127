import React from "react";

const SupportCard = () => {
    return (
        <div className="support-card card card--purple-bg">
            <div className="support-box">
                <div className="support-box__title">Live Chart Support</div>
                <div className="support-box__subtitle">Do you need any help?</div>
                <button className="support-box__btn">Start</button>
            </div>
        </div>
    )
};

export default SupportCard;