import React from "react";
import moment from "moment";
import AppsTableRowStatus from "./AppsTableRowStatus";

const AppsTableRow = ({createdAt, id, paymentCycleDuration, loanAmount, adj, status}) => (
    <tr>
        <td className="table-date">{moment(createdAt).format("LL")}</td>
        <td>{id.substr(0,9)}</td>{console.log(status)}
        <td>{paymentCycleDuration}</td>
        <td className="table-amount text-left">{loanAmount}</td>
        <td>{adj ? adj.name.split(" ")[0] : "Unassigned"}</td>
        <td>
            <AppsTableRowStatus status={status}/>
        </td>
    </tr>
)

export default AppsTableRow;