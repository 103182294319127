import React, {useState} from "react";
import { Controller } from "react-hook-form";
import ReactPasswordStrength from "react-password-strength";

import IconsEye from "../assets/svg/icons-eye.svg";
import IconsEyeHidden from "../assets/svg/icons-eye-hidden.svg";

const FormPasswordInput = (props) => {
    const [hidePassword, togglePassword] = useState(true);
    console.log(props.name)
    return (
        <div className="input-with-icon">
            <Controller
                as={props.strength ? ReactPasswordStrength : <input />}
                name={props.name || "password"}
                inputProps={{
                    placeholder: "******",
                    className: "form-control",  
                    type: hidePassword ? "password" : "text",
                    autoComplete: "off"
                }}
                maxLength={20}
                minLength={6} 
                placeholder="******"
                {...props}
                type={hidePassword ? "password" : "text"}
            />
            <div className="input-icon-div" onClick={() => togglePassword(!hidePassword)}>
                <i style={{ width:"20px", height: "20px",  backgroundPosition: "center center", background: `url(${hidePassword ? IconsEyeHidden : IconsEye}) no-repeat center center`}} alt="" />
            </div>
        </div>
    )
}

export default FormPasswordInput;