import { useState } from "react";

const useStep = ({ initialStep = 0, steps }) => {
  if (process.env.NODE_ENV !== "production") {
    if (!Array.isArray(steps) && !Number.isInteger(steps)) {
      throw new Error(
        "useStep: You must specify either an array or an integer for `steps`"
      );
    }
  }

  if (process.env.NODE_ENV !== "production") {
    if (typeof initialStep === "string" && initialStep === -1) {
      throw new Error(
        `useStep: id of "${initialStep}" specified in initialStep not found in steps`
      );
    }
  }

  const stepTo = (direction = 1) => {
    const newStep = (index + steps.length + direction) % steps.length;
    if (newStep < 0 || newStep > steps.length) {
      throw new Error(`useStep: Index out of range in go(${newStep})`);
    }
    setStep(newStep);
  };

  const [index, setStep] = useState(initialStep);
  const step = steps[index];

  const navigation = {
    next: () => stepTo(1),
    previous: () => stepTo(-1),
    goTo: (step) => setStep(step), // needs refactoring to prevent out of bound stepping
  };

  return {
    navigation,
    step,
    index,
  };
};

export default useStep;
