import React from "react";
import moment from "moment";


const NotificationsTableRow = ({createdAt, title, message}) => (
    <tr>
        <td className="table-date">{moment(createdAt).fromNow()}</td>
        <td>{title}</td>
        <td>{message}</td>
    </tr>
)

export default NotificationsTableRow;