import React, { useState } from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { withApollo, useLazyQuery, useMutation } from "react-apollo";
import { Link } from "react-router-dom";

// Components

// Query
import { client } from "../../../graphql/client";

import {
    AUTH_SIGNOUT_MUTATION,
    USER_PROFILE_QUERY
} from "../../../graphql";

// CSS
import "./styles.css";

import { useUser } from "../../../hooks";

// Images
import logoSmall from "../../../assets/images/logo-white.png";
import logoMedium from "../../../assets/images/logo-white@2x.png";
import logoLarge from "../../../assets/images/logo-white@3x.png";
import SvgMailbox from "../../../assets/svg/mailboxwithcloseletter.svg";

import {
    AUTH_VERIFY_USER_MUTATION
} from "../../../graphql";

const VerifyAccount = ({ location, history }) => {
 const me = useUser();
   
    const [
        loadMe,
        { called: calledMe, data: userProfile, loading: loadingMe, refetch },
    ] = useLazyQuery(USER_PROFILE_QUERY, {
        fetchPolicy: "no-cache",
    });

    const [activateUser] = useMutation(AUTH_VERIFY_USER_MUTATION);
    const [checkActiveUser, setCheckActiveUser] = useState(true);

    if (!calledMe) {
        loadMe();
    }
    
    
    if (calledMe && !loadingMe && checkActiveUser === true) {
        if (userProfile.me.active === false) {
        const tokens = location.search.split("?");
        if (!userProfile.me.active && tokens.length > 0 && tokens[1]) {
        const httparams = tokens[1].split("&");
        const email = httparams[0].split("=")[1];
        const hash = httparams[1].split("=")[1];

        try {
            activateUser({
            variables: {
                email,
                hash,
            },
                refetchQueries: [{ query: USER_PROFILE_QUERY }],
            });
            setCheckActiveUser(false);
        } catch (e) {
            console.log(e.message.replace("GraphQL Error: ", ""));
        }
        }
    } else {
        refetch();
        setCheckActiveUser(false);
     }
    }

  const logOut = async () => {
    await client.mutate({
      mutation: AUTH_SIGNOUT_MUTATION,
      refetchQueries: [{ query: USER_PROFILE_QUERY }],
    });
    return client.cache.reset();
  };

  if(loadingMe){
      return <div className="loading-overlay"><div className="loading"></div></div>
  }
  return (
    <div className="login-grid">
        <header className="header">
            <Link className="margin-t-xl" to={`${process.env.PUBLIC_URL}/`}>
                <img 
                    className="margin-t-md header__logo"
                    srcSet={`
                    ${logoSmall} 1x,
                    ${logoMedium} 2x,
                    ${logoLarge} 3x   
                    `}
                    alt="Lendle"
                />
            </Link>
            <p className="header__subtitle">
                ushering a new era of banking, client focused.
            </p>
        </header>
        <main className="container">
            <div className="main__cards" style={{flex: 1, paddingLeft: 0, paddingRight: 0}}>
                <div className="card padding-md card--rounded card--shadow margin">
                    <div className="card__header margin-b-md">
                        <div className="card__title text-center">
                            Verify Your Email
                        </div>
                    </div>
                    <div className="card__body d-flex flex-column text-center justify-content-center margin-t-lg ">
                        <img src={SvgMailbox} alt="Mailbox" className="margin-t-sm" style={{height: "290px"}} />
                        <p style={{color: "rgb(161, 174, 197)", padding: "0 3em", marginBottom: "1.5em", fontSize: "--var(--text-sm)"}}>An email has been sent to {me.email} with a link to verify your account. If you have not received the email after a few minutes, please check your spam folder.</p>
                        <div className="d-flex justify-content-center">
                            <button style={{textDecoration: "none", marginRight: "0.5em"}} className="btn btn-info margin-t-sm margin-b-sm">Resend Email</button>
                            <button style={{textDecoration: "none", marginLeft: "0.5em"}} className="btn btn-info-outline margin-t-sm margin-b-sm">Contact Support</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="signup__link">
                <a onClick={logOut} style={{cursor: "pointer"}}>Sign Out</a>
            </div>
        </main>
    </div>
  );
};

export default withRouter(withApollo(VerifyAccount));
