import React from "react";
import {useModal} from "../../hooks";
import {
    DocumentFiles,
    ModalBox
} from "../../components";

import IconsInfo from "../../assets/icons/icons-info.svg";

const Documents = React.memo(({sidebarState, isDesktop}) => {
    const [modalIsOpen, closeModal, openModal] = useModal();
    return (
        <>
        <ModalBox
             modalIsOpen={modalIsOpen}
             openModal={openModal}
             closeModal={closeModal}
             title={"Additional information about documents"}
        >
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br /> sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />
                Ut enim ad minim veniam, quis nostrud exercitation ullamco <br /> laboris nisi ut aliquip ex ea commodo consequat.  <br />
                Duis aute irure dolor in reprehenderit in voluptate velit <br /> esse cillum dolore eu fugiat nulla pariatur.  <br />
                Excepteur sint occaecat cupidatat non proident.  <br /> Excepteur sint occaecat cupidatat non. 
            </p>
        </ModalBox>
        <main className="app-body main">
            <div className="main__title mobile-only d-flex justify-space-between align-items-center">
                Documents
                <a onClick={openModal} className="docs-infobox__icon">
                    <img src={IconsInfo} alt="doc info" />
                </a>
            </div>
            <div className="app-cards" style={{ transform: !sidebarState && !isDesktop ? "translateX(220px)" : "translateX(0px)" }}>
                <div className="documents">
                    <DocumentFiles />
                </div>
                <div className="docs-infobox card padding-lg card--rounded card--shadow">
                    <div className="docs-infobox__title">
                        <div className="docs-infobox__icon">
                            <img src={IconsInfo} alt="doc info" />
                        </div>
                        Additional information about documents
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                        Excepteur sint occaecat cupidatat non proident. Excepteur sint occaecat cupidatat non. 
                    </p>
                </div>
            </div>
        </main>
        </>
    )
});

export default Documents;