import React from "react";

const Closed = () => (<div className="table-pill">
<i className="table-pill--closed" alt="missed payment"></i>
<span>Closed</span>
</div>);


const Pending = () => (<div className="table-pill">
    <i className="table-pill--pending" alt="missed payment"></i>
    <span>Pending</span>
</div>);


const Review = () => (<div className="table-pill">
    <i className="table-pill--review" alt="missed payment"></i>
    <span>In Review</span>
</div>);


const Cancelled = () => (<div className="table-pill">
    <i className="table-pill--missed" alt="missed payment"></i>
    <span>Cancelled</span>
</div>);


const Successful = () => (<div className="table-pill">
    <i className="table-pill--made" alt="missed payment"></i>
    <span>Approved</span>
</div>);

const AppsTableRowStatus = ({status}) => {
    switch(status.toLowerCase()){
        case "closed":
            return <Closed />
        case "cancelled": 
            return <Cancelled />
        case "approved":
            return <Successful />
        case "inreview": 
            return <Review />
        case "pending": 
            return <Pending />
        default:
            return "N/A"
    }
}
export default AppsTableRowStatus;