import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { AppButton, ModalBox, MobileOnly } from "../components";
import { useModal, useWindowResize } from "../hooks";

import Header from "./Header";
import Sidenav from "./Sidenav";

import IconsLoan from "../assets/icons/iconsLoan.svg";
import IconsAdd from "../assets/icons/icons-add.svg"
import HandshakeSvg from "../assets/svg/handshake.svg"
import HeadsetSvg from "../assets/svg/headset.svg"

import {
    SIDEBAR_TOGGLE,
    SIDEBAR_CLOSE
} from "../redux/actions/constants"

const SupportView = () => (
    <div className="d-flex flex-column">
        <div className="card padding-lg card--rounded card--shadow text-center margin-t-lg">
           <div className="d-flex flex-column align-items-center">
                <img alt="support" style={{width: "100px"}} src={HandshakeSvg}  className="margin-b-md" />
               <span style={{fontWeight: "bold", marginBottom: "0.5rem"}}>Customer Service:</span>
               <a href="mailto:info@lendle.com">info@lendle.com</a>
           </div>
        </div>
        <div className="card padding-lg card--rounded card--shadow text-center margin-t-md">
           <div className="d-flex flex-column align-items-center">
               <img alt="support" style={{width: "100px"}} src={HeadsetSvg}  className="margin-b-md"/>
               <span style={{fontWeight: "bold", marginBottom: "0.5rem"}}>Help & Support:</span>
               <a href="mailto:support@lendle.com">support@lendle.com</a>
           </div>
        </div>
    </div>
)
const CreateAppView = () => (
    <div className="modal__options">
        <Link to={`${process.env.PUBLIC_URL}/card/apply`} id="modal-credit-app-btn" className="modal__option card">
            <div className="inner-wrapper">
            <img src={IconsAdd} alt="card application" style={{width: "12px", height: "12px"}}/>
            Credit Card Application
            </div>
        </Link>
        <Link to={`${process.env.PUBLIC_URL}/dashboard/request`} className="modal__option card">
            <div id="modal-loan-btn">
                <img src={IconsLoan} alt="loan application" style={{width: "30px", height: "30px"}}/>
                <button className="btn btn-info margin-t-sm">Loan Application</button>
            </div>
        </Link>
    </div>
)
const Page = ({ className, title, children }) => {

    const history = useHistory();
    const size = useWindowResize();
    const [isDesktop, setIsDesktop] = useState(false);
    const [modalIsOpen, closeModal, openModal] = useModal();
    const [modalView, setModalView] = useState("createAppView")
    const [modalTitle, setModalTitle] = useState("Would you like to apply for Credit Card or Loan?");

    const dispatch = useDispatch();
    const sidebarState = useSelector(state => state.uiReducer.hideSidebar);

    const toggleSidebar = useCallback(() => dispatch({ type: SIDEBAR_TOGGLE }), [dispatch]);
    const hideSidebar = useCallback(() => dispatch({ type: SIDEBAR_CLOSE }), [dispatch]);

    useEffect(() => {
        if( size.width > 1079 ){
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }
        
        history.listen(() => {
            if(sidebarState === false){
                hideSidebar()
            }
            if(modalIsOpen){
                closeModal();
            }
        })

    }, [size, history, hideSidebar, sidebarState, modalIsOpen, closeModal]);

    const childrenWithProps = React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { isDesktop, sidebarState, openModal });
        }
        return child;
    });


    const openSupportModal = () => {
        setModalTitle("Have a question? Contact us below")
        setModalView("supportView");
        openModal();
    }

    const openCreateAppModal = () => {
        setModalTitle("Would you like to apply for Credit Card or Loan?");
        setModalView("createAppView");
        openModal();
    }

    return (
        <div className={className}>
            <ModalBox  
                modalIsOpen={modalIsOpen}
                openModal={openModal}
                closeModal={closeModal}
                title={modalTitle}
            >
                { modalView === "createAppView" && <CreateAppView />}
                { modalView === "supportView" && <SupportView /> }
            </ModalBox>
            <Header 
                title={title} 
                sidebarState={sidebarState} 
                toggleSidebar={toggleSidebar} 
                openCreateAppModal={openCreateAppModal} 
                openSupportModal={openSupportModal}
            />
            <Sidenav isDesktop={isDesktop} sidebarState={sidebarState} />
            {childrenWithProps}
            <MobileOnly>
                <AppButton onClick={openCreateAppModal} />
            </MobileOnly>
        </div>
    )
}

export default Page;