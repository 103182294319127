import { useQuery } from "react-apollo";
import { USER_PROFILE_QUERY } from "../graphql";

const useUser = () => {
  // FETCH USER LOGGED IN
  const { data, loading } = useQuery(USER_PROFILE_QUERY);
  if(loading){
    return {};
  }
  if (data) {
    return data.me;
  }
};

export default useUser;
