import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const purposeOptions = [
    { value: "Future Needs", label: "Future Needs" },
    {
      value: "Home Renovations / Maintenance",
      label: "Home Renovations / Maintenance",
    },
    {
      value: "Vacation / Travel",
      label: "Vacation / Travel",
    },
    {
      value: "Vehicle Purchase",
      label: "Vehicle Purchase",
    },
    {
      value: "Major Expenses e.g. appliances, wedding",
      label: "Major Expenses e.g. appliances, wedding",
    },
    { value: "Investements", label: "Investements" },
    {
      value: "Balance of Account Transfer",
      label: "Balance of Account Transfer",
    },
    {
      value: "Personal / Family Emergency",
      label: "Personal / Family Emergency",
    },
    {
      value: "Education/Tuition",
      label: "Education/Tuition",
    },
    {
      value: "Debt Consolidation",
      label: "Debt Consolidation",
    },
  ];


const customStyles = {
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: 'rgba(var(--light-gray), 1)',
        }
    },
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        height: "45px",
        width: "100%",
        border: "solid 1px rgba(182, 195, 217, 0.25)",
        marginTop: "var(--space-sm)",
        marginBottom: "var(--space-sm)",
        borderRadius: "5px",
        color: "rgba(var(--black), 1)",
        paddingLeft: "5px",
        paddingTop: "2px",
        fontSize: "var(--text-xs)",
        transition: "background-color 0.5s, border-color 0.5s",
        // You can also use state.isFocused to conditionally style based on the focus state
    })
}

const FormBorrowPurposeSelect = ({ control, rules }) => {
  return (
    <Controller
      as={Select}
      control={control}
      name="borrowPurpose"
      options={purposeOptions}
      placeholder="Purpose Of Borrow"
      rules={rules}
      classNamePrefix="react-select"
      styles={customStyles}
    />
  );
};

export default FormBorrowPurposeSelect;
