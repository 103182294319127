import React from "react";

import IconsMenu from "../assets/icons/menu.svg";
import logoSmall from "../assets/images/logo.png";
import logoMedium from "../assets/images/logo@2x.png";
import logoLarge from "../assets/images/logo@3x.png";
import IconsMessage from "../assets/icons/message.svg";
import IconsClose from "../assets/icons/iconsClose.svg";

import { Avatar, NotificationDropdown } from "../components";
import { useUser } from "../hooks";


const Header = ({title, sidebarState, toggleSidebar, openCreateAppModal, openSupportModal}) => {
    const me = useUser();
    return (
        <>
        <header className="header">
            <div className="header__menu">
                <img 
                className="header__item mobile-only"
                srcSet={`
                    ${logoSmall} 1x,
                    ${logoMedium} 2x,
                    ${logoLarge} 3x   
                    `}
                alt="Lendle"
                />
                <div className="header__spacer mobile-only"></div>
                <img className="header__item mobile-only burger-menu" onClick={toggleSidebar} src={!sidebarState ? IconsClose : IconsMenu} alt="menu" />
                <div className="main__title desktop-only">
                    {title}
                </div>
            </div>
            <div className="header__widgets">
                <div className="header__item desktop-only">
                    <button onClick={openCreateAppModal} className="btn btn-primary">+ Create Application</button>
                </div>
                <NotificationDropdown />
                <div className="header__item" onClick={openSupportModal}>
                    <svg width="18" height="14" viewBox="0 0 18 14">
                        <g fillRule="evenodd">
                            <g>
                                <g>
                                    <path d="M16 0c1.105 0 2 .895 2 2v10c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V2C0 .895.895 0 2 0h14zm-.09 2.655c-.302-.462-.921-.593-1.384-.292L8.928 6.007 3.331 2.363l-.101-.058c-.448-.223-1.002-.08-1.282.35-.302.463-.17 1.083.292 1.384l6.143 4 .114.064c.312.15.682.128.977-.064l6.143-4 .094-.07c.385-.32.478-.884.198-1.314z" transform="translate(-257 -55) translate(257 55)"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <Avatar name={me.name} id={me.id} />         
            </div>
        </header>
        </>
    );
}

export default Header;