import React from "react";
import { useQuery, useMutation } from "react-apollo";

import { USER_LOAN_QUERY, USER_PROFILE_QUERY, USER_UPDATE_SHOW_TOUR } from "../../graphql";

import {
    MetricsCard,
    ScheduleCard,
    ScoreCard,
    SupportCard,
    WelcomeCard,
    MobileOnly
} from "../../components";

import { useUser } from "../../hooks";

const Dashboard = React.memo(({sidebarState, isDesktop, openModal}) => {

    const me = useUser();
    
    const [updateShowTour] = useMutation(
        USER_UPDATE_SHOW_TOUR
      );

    const { data, loading } = useQuery(USER_LOAN_QUERY, {
        fetchPolicy: "network-only",
    });

    const approvedLoan =
    !loading && data && data.loanApplications
        ? data.loanApplications.filter((app) => app.status === "APPROVED")
        : [];

    React.useEffect(() => {
        const closeTour = async () => {
            await updateShowTour({
                refetchQueries: [
                  {
                    query: USER_PROFILE_QUERY,
                  },
                ],
                variables: {
                  id: me.id,
                },
              });
        }

        if(me.showTour === true){
            openModal();
            closeTour();
        }
    }, [openModal, me, updateShowTour]);

    return (
        <main className="main" >
            <div className="main__title mobile-only">
                Dashboard Overview
            </div>
            <div className="main__cards" style={{ transform: !sidebarState && !isDesktop ? "translateX(220px)" : "translateX(0px)" }}>
                 <MobileOnly>
                    <WelcomeCard/>
                 </MobileOnly>
                 <ScoreCard />
                    {
                        loading ? "" : 
                        <MetricsCard 
                            loan={approvedLoan}
                            me={me}
                        />
                    }
                 <ScheduleCard 
                    data={approvedLoan}
                 />
                 <SupportCard />
            </div>
        </main>
    )
});

export default Dashboard;