import React from "react";
import CardApplyForm from "./CardApplyForm";
import { InfoCard, ModalBox } from "../../components";
import { Link } from "react-router-dom"

import { useModal } from "../../hooks";

const CardApply = ({sidebarState, isDesktop, history}) => {
    const [modalIsOpen, closeModal, openModal] = useModal();

    return (
    <main className="app-body main">
        <ModalBox 
            modalIsOpen={modalIsOpen}
            openModal={openModal}
            closeModal={closeModal}
            title="Verifcation"
        >
           <h4>Application Request Completed</h4>
            <p>Congratulations, we have all that we need from you. <br />You can check the status of your application below.</p>
            <Link to={`${process.env.PUBLIC_URL}/dashboard/applications`} className="btn btn-info margin-t-sm margin-b-sm">View My Application</Link>
        </ModalBox>
        <div className="main__title mobile-only d-flex justify-space-between align-items-center">
            Secured Mastercard Application
        </div>
        <div className="profile-cards" style={{ transform: !sidebarState && !isDesktop ? "translateX(220px)" : "translateX(0px)" }}>
        <div className="credit-app card padding-md card--rounded card--shadow">
            <div className="card margin-desktop-r-sm">
                <div className="card__title">Credit Card Details</div>
                <div className="card__content">
                    <CardApplyForm onFinish={openModal} />
                </div>
            </div>
        </div>
        <div className="credit-app__info margin-desktop-l-xs card padding-md card--rounded card--shadow">
            <div className="card__header">
                <div className="d-flex justify-space-between card__title">
                    Personal Information
                    <button onClick={() => history.push(`${process.env.PUBLIC_URL}/dashboard/me`)} className="form-edit-btn" />
                </div>
            </div>
            <div className="card__content">
                <InfoCard />
            </div>
            </div>
        </div>
    </main>
    )
}

export default CardApply;