import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";

// Components
import SignUpForm from "./SignUpForm";

// CSS
import "./styles.css";

// Images
import logoSmall from "../../../assets/images/logo-white.png";
import logoMedium from "../../../assets/images/logo-white@2x.png";
import logoLarge from "../../../assets/images/logo-white@3x.png";

const SignUp = ({ client, history }) => {
  const onSuccess = async (result) => {
    if (result.data) {
      toast.success(
        "Successfully Registered! We sent a confirmation link to your email."
      );
      client.cache.reset();
      history.push("/dashboard/overview");
    }
  };

  return (
    <div className="login-grid">
        <header className="header">
            <Link className="margin-t-xl" to={`${process.env.PUBLIC_URL}/`}>
                <img 
                    className="margin-t-md header__logo"
                    srcSet={`
                    ${logoSmall} 1x,
                    ${logoMedium} 2x,
                    ${logoLarge} 3x   
                    `}
                    alt="Lendle"
                />
            </Link>
            <p className="header__subtitle">
                ushering a new era of banking, client focused.
            </p>
        </header>
        <main className="container">
            <div className="main__cards" style={{flex: 1, paddingLeft: 0, paddingRight: 0}}>
                <div className="card padding-md card--rounded card--shadow margin">
                    <div className="card__header margin-b-md">
                        <div className="card__title">
                            Create Profile
                        </div>
                    </div>
                    <div className="card__body d-flex flex-column justify-content-center">
                        <SignUpForm onSuccess={onSuccess} />
                    </div>
                </div>
            </div>
            <div className="signup__link">
                <span>Already have an account? <Link to={`${process.env.PUBLIC_URL}/login`}>Sign In</Link></span>
            </div>
        </main>
        {/* <footer className="footer">
            <div className="footer__text">
                Copyright © Lendle 2020. All Rights reserved.
            </div>
        </footer> */}
    </div>
  );
};

export default withApollo(SignUp);
