import React from "react";

import IconsDocument from "../assets/icons/iconsDocument.svg";
import IconsEye from "../assets/icons/iconsEye.svg";
import IconsDelete from "../assets/icons/iconsDelete.svg";

const DocumentFile = ({file, onView, onDelete}) => (
    <div className="documents__file">
        <div className="documents__filename">
            <div className="document__icon">
                <img src={IconsDocument} alt="file" />
            </div>
            {file.file.split("/")[1]}
        </div>
        <div className="documents__actions">
            <a onClick={() => onView(file.file)} className="document__action">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                    <g fillRule="evenodd">
                        <g>
                            <g>
                                <g>
                                    <g transform="translate(-255 -294) translate(25 260) translate(225 25) translate(5 9)">
                                        <path d="M10 0c3.509 0 6.693 1.806 9.554 5.418.274.347.288.832.035 1.195C17.072 10.204 13.876 12 10 12 6.242 12 3.123 10.311.642 6.934l-.23-.321c-.254-.362-.24-.848.034-1.195C3.306 1.806 6.491 0 10 0zm0 2C7.79 2 6 3.79 6 6s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"/>
                                        <circle cx="10" cy="6" r="2"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </a>
            <a onClick={() => onDelete(file.id)} className="document__action">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15">
                    <g fillRule="evenodd">
                        <g>
                            <g>
                                <g>
                                    <g transform="translate(-304 -388) translate(25 355) translate(270 25) translate(9 8)">
                                        <g>
                                            <path d="M1.095 0h7.81c.552 0 1 .448 1 1 0 .03-.001.06-.004.09l-.736 8.091C9.072 10.211 8.208 11 7.174 11H2.826C1.792 11 .928 10.211.835 9.181l-.736-8.09C.05.54.454.054 1.004.004c.03-.003.06-.004.091-.004z" transform="translate(1 4)"/>
                                            <path stroke="#FFF" strokeLinecap="round" d="M3 3L3 8M5 3L5 8M7 3L7 8" transform="translate(1 4)"/>
                                        </g>
                                        <rect width="12" height="2" y="1" rx="1"/>
                                        <rect width="2" height="2" x="5" rx="1"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </a>
        </div>
    </div>
)
export default DocumentFile;