import moment from 'moment';
import currency from "currency.js";

export default data => {
  if (!data) {
    return {};
  }

  const loansApproved = data.filter(
    l => l.status === 'APPROVED'
  );
  const loansPending = data.filter(
    l => l.status === 'PENDING'
  );
  const currentLoan = data.filter(
    app => app.status === 'APPROVED'
  )[0];

  const hasLoansApproved =
    loansApproved && loansApproved.length > 0;
  const hasPayments =
    currentLoan && currentLoan.payments.length > 0;

  const loansTotalAmount = hasLoansApproved
    ? loansApproved
      .map(p => p.loanAmount)
      .reduce((prev, curr) => prev + curr)
    : 0;
  const loanAmount = currentLoan
    ? currency(currentLoan.loanAmount).value
    : 0;

  const loanAmountPaid = hasPayments
    ? currentLoan.payments
      .map(p => p.amount)
      .reduce((prev, curr) => prev + curr)
    : 0;
  
  const loansTotalAmountPaid = hasPayments  
    ? currentLoan.payments
      .map(p => p.amount)
      .reduce((prev, curr) => currency(prev).add(curr).value)
    : 0;

  const loansTotalProfit = loansApproved.length > 0
    ? loansApproved.map(p => parseFloat(p.loanFee) + parseFloat(p.loanFeeTax))
      .reduce((prev, curr) => prev + curr)
    : 0;


  const loanFee = currentLoan ? currency(currentLoan.loanFee).value : 0

  const loanBalance = currentLoan ? currency(loanAmount).add(loanFee).subtract(loanAmountPaid).value : 0;

  let loanPaymentDate = Infinity;
  let loanPayCountdown = '0';

  const payDates =
    loansApproved.length > 0
      ? loansApproved[0].paymentCycleSchedule
      : null;

  if (payDates !== null) {
    const now = moment(
      loansApproved[0].paymentCycleStartDate
    ).toDate();

    payDates.forEach(function(d) {
      const date = new Date(d);

      if (
        date >= now &&
        (date < new Date(loanPaymentDate) ||
          date < loanPaymentDate)
      ) {
        loanPaymentDate = d;
      }
    });

    const from = now;
    const to = loanPaymentDate;

    loanPayCountdown = Math.abs(
      moment(from, 'YYYY-MM-DD')
        .startOf('day')
        .diff(
          moment(to, 'YYYY-MM-DD').startOf('day'),
          'days'
        )
    );
  }

  return {
    loanAmount,
    loanAmountPaid,
    loanBalance,
    loanPayCountdown,
    loanPaymentDate,
    loansApproved,
    loansPending,
    loansTotalAmount,
    loansTotalAmountPaid,
    loansTotalProfit
  };
};
