import React, { useState } from "react";

// Essential imports
import moment from "moment";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useMutation } from "react-apollo";
import { isValidPhoneNumber } from "react-phone-number-input";
import IconsEye from "../../../assets/svg/icons-eye.svg";
import IconsCheck from "../../../assets/icons/icons-check-blue.svg"

// Auth components we need
import {
  FormDobSelect,
  FormPhoneInput,
  FormPasswordInput,
  ModalBox,
} from "../../../components";

// Auth and User queries
import {
  AUTH_SIGNUP_MUTATION,
  USER_PROFILE_QUERY,
  USER_LOAN_QUERY,
} from "../../../graphql";

import { useModal } from "../../../hooks";


const emailRegex = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  message: "invalid email address",
};

const SignUpForm = ({ onSuccess }) => {
  // Setup for ReactPasswordStrength Component
  const [passData, setPassData] = useState({
    score: null,
    password: null,
  });

  const [fetchResult, setFetchResult] = useState({})
  // Initialize our form hooks
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    reset,
    watch
  } = useForm({
    autoComplete: "off"
  });
  // Signup muttion
  const [runSignup, { loading }] = useMutation(AUTH_SIGNUP_MUTATION);
  const [modalIsOpen, closeModal, openModal] = useModal();

  const watchTos = watch(["tos"]); // you can also target specific fields by their names

  const onSubmit = async (data) => {

    if (data === "") {
      errors.showMessages();
      return;
    }

    // Deconstruct some form data
    const { fname, lname, dobDay, dobMonth, dobYear } = data;

    // Format our date for moment
    const dob = `${dobDay.value}-${dobMonth.value}-${dobYear.value}`;

    if (moment().diff(dob, "years") < 18) {
      alert("You must be atleast 18 years of age to sign up");
      return;
    }

    // Build full name
    const name = `${fname} ${lname}`;

    // Query variables
    const variables = {
      ...data,
      name,
      dob,
      password: passData.password,
    };

    // Flush some important queries
    const refetchQueries = [
      { query: USER_PROFILE_QUERY },
      { query: USER_LOAN_QUERY },
    ];

    try {
      // Attempt the sign up mutation
      const signup = await runSignup({
        variables,
        refetchQueries,
      });

      setFetchResult(signup);
      // Clear form
      reset();
      // Run our post signup function
      openModal();

    } catch (error) {
      setTimeout(() => {
        toast.error(error.message.replace("GraphQL error: ", ""));
      }, 200);
    }
  };

  const checkPasswordsMatch = (value) =>
    passData.password === value || "Passwords don't match.";

  return (
    <form className="form" noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="new-password">
      {loading && 
        <div className="loading-overlay">
            <div className="loading"></div>
        </div>
      }
      <ModalBox 
        modalIsOpen={modalIsOpen}
        openModal={openModal}
        closeModal={closeModal}
        title="Verifcation"
      >
        <p>Click the link in your email to confirm your account. <br />If you can't find the email check your spam folder.</p>
        <button onClick={() => onSuccess(fetchResult)} className="btn btn-info margin-t-sm margin-b-sm"  style={{textDecoration: "none", width: "100%", marginBottom: "0.2em"}} >Ok</button>
        <button  className="btn btn-info-outline margin-t-sm margin-b-sm" style={{textDecoration: "none", width: "100%", marginBottom: "0.2em"}}>Resend Code</button>
      </ModalBox>
      <fieldset aria-busy={loading} disabled={loading}>
        {console.log(errors)}
        {
          Object.keys(errors).length > 0 && <div className="form__message">
              {errors.fname && <span>First Name is required</span>}
                {errors.lname && <span>Last Name is required</span>}
                {errors.email && (<span>{errors.email.message !== "" ? errors.email.message : "Email is required"}</span> )}
                {errors.phone && <span>Invalid Phone Number</span>}
              {(errors.dobDay || errors.dobMonth || errors.dobYear) && (
              <span>Your date of birth is required</span>
            )}
            {errors.password && <span>Invalid Password</span>}
              {errors.confirmPassword && (
                <span>
                  {errors.confirmPassword.message !== ""
                    ? errors.confirmPassword.message
                    : "Confirm Password Required"}
                </span>
            )}
            {errors.tos && <span>You must agree to our terms and conditions</span>}

        </div>
        }
        <div className="form__group">
            <div className="form__col">
                <input type="text" placeholder="Firstname" name="fname" className="form-control" ref={register({required: true })} />
            </div>
            <div className="form__col">
                <input type="text" placeholder="Lastname" name="lname" className="form-control" ref={register({required: true })}  /> 
            </div>
        </div>
        <div className="form__group">
            <div className="form__col">
                <input type="email" placeholder="Your Email" name="email" className="form-control" ref={register({required: true, pattern: emailRegex})} />              
            </div>
            <div className="form__col">
              <FormPhoneInput
                control={control}
                rules={{
                  required: true,
                  validate: (value) => isValidPhoneNumber(value),
                }}
              />          
            </div>
        </div>
        <div className="form__group">
              <div className="form__col">
                    <FormPasswordInput
                      strength
                      controlled
                      changeCallback={({ score, password, isValid }) => {
                        setValue("password", password, { shouldValidate: true });
                        setPassData({
                          password,
                          score,
                          isValid,
                        });
                      }}
                      control={control}
                      rules={{
                        required: true,
                        validate: () => passData.isValid,
                      }}
                    />          
              </div>
              <div className="form__col">
                  <FormPasswordInput
                      controlled
                      name="confirmPassword"
                      control={control}
                      rules={{
                        required: true,
                        validate: checkPasswordsMatch,
                      }}
                      style={{paddingTop:"16px"}} 
                    />  
              </div>
        </div>
        <div className="form__dob form__select">
           <FormDobSelect control={control} rules={{ required: true }} />    
        </div>
        <div className="form__row">
             <div className="terms">
             <div className="form__checkbox">
                <input 
                  type="checkbox" 
                  name="tos"
                  ref={register({
                    required: true
                  })} className="terms__checkbox" 
                />
            {watchTos.tos && <img style={{cursor: "pointer"}} src={IconsCheck} onClick={() => setValue("tos", false)} alt="TOS" />}
            </div>
            <span className="terms__text">Creating an account means you’re okay with our <a target="_blank" rel="noopener noreferrer" href="https://lendle.ca/terms.html">Terms of Service</a> and <a target="_blank" rel="noopener noreferrer" href="https://lendle.ca/privacy-policy.html">Privacy Policy</a></span>        </div>
        </div>
        <button id="auth-form-btn" style={{textDecoration: "none", width: "100%"}} disabled={Object.keys(errors).length > 0} className="btn btn-info margin-t-sm margin-b-md">Sign Up</button>
      </fieldset>
    </form>
  );
};

export default SignUpForm;
