import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { 
    FIRST_ANIMATION
} from "../redux/actions/constants";

import ScoreCardRow from "./ScoreCardRow";
import ScoreGauge from "./ScoreGauge";

import { EmptyTransactions } from "components";

import { useScore } from "../hooks";

const ScoreCard = () => {

    const lendleScore = 100;
    const dispatch = useDispatch();
    
    const animationState = useSelector(state => state.uiReducer.firstanimation);
    const {score, updateScore} = useScore({ initialScore: animationState === false ? 0 : lendleScore });
    
    const setFirstAnimation = useCallback(() => { 
        dispatch({
            type: FIRST_ANIMATION
        })
     }, [dispatch])

     
    useEffect(() => {
        const timer = setTimeout(() => {
            if(animationState === false){
                updateScore(lendleScore)
                setFirstAnimation();
            }
        }, 100)
        return () => {
            clearTimeout(timer)
        }
    }, [updateScore, animationState, setFirstAnimation]);

    return (
        <div className="score-card card card--rounded card--shadow d-flex flex-column" style={{overflow: "hidden"}}>
            <div className="card padding-x-md padding-t-md">
                <div className="card__header margin-b-md">
                    <div className="card__title">Lendle Score</div>
                </div>
                <div className="card__body padding-md d-flex justify-content-center">
                    <ScoreGauge score={score}/>
                </div>
            </div>
            <div className="card padding-md flex-full d-flex flex-column">
                <div className="card__header margin-b-md">
                    <div className="d-flex justify-space-between align-items-center  card__title">
                        Score Activity
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/activity`} className="card__button">See all activity</Link>
                    </div>
                </div>
                <div className="card__body flex-full" style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr"}}>
                    {/* <div className="score-activity margin-b-md">
                        <ScoreCardRow title="Successful Payment" subtitle="20.08.2020" amount={"+20%"} status="positive"/>
                        <ScoreCardRow title="Successful Payment" subtitle="20.08.2020" amount={"+20%"} status="positive"/>
                        <ScoreCardRow title="Missed Payment" subtitle="20.08.2020" amount={"+20%"} status="negative"/>
                        <ScoreCardRow title="Missed Payment" subtitle="20.08.2020" amount={"+20%"} status="negative"/> 
                    </div> */}
                    <EmptyTransactions type="activities" />
                </div>
            </div>
        </div>
    )
};

export default ScoreCard;