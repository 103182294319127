import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "react-apollo";
import loanCalc from "../../../services/loanCalc";
import { toast } from "react-toastify";

import moment from "moment";

import { useUser, useModal } from "../../../hooks";
import { ModalBox } from "../../../components";
import { LOAN_REQUEST_MUTATION, USER_ACTIVITY_QUERY, USER_LOAN_QUERY } from "../../../graphql";

const Complete = ({
    wizardData,
    navigation: { previous },
  }) => {

    const me = useUser();
    const [modalIsOpen, closeModal, openModal] = useModal();
    const [performLoanRequest, { loading }] = useMutation(LOAN_REQUEST_MUTATION);
    const [finish, setFinish] = React.useState(false);
    const loanData = React.useMemo(() => loanCalc(
        wizardData.borrowAmount,
        wizardData.borrowTerm.value,
        wizardData.paymentTerm.value,
        wizardData.paymentCycleStartDate
    ), [wizardData.borrowAmount, wizardData.paymentCycleStartDate, wizardData.borrowTerm.value, wizardData.paymentTerm.value]);

    const onSubmit = async () => {
            try {
                await performLoanRequest({
                  variables: {
                    name: me.name,
                    email: me.email,
                    phone: me.phone,
                    street: me.residentialAddress.street,
                    city: me.residentialAddress.city,
                    postal: me.residentialAddress.postal,
                    province: me.residentialAddress.province,
                    country: "Canada",
                    bankStatement: wizardData.front.file,
                    proofOfResidence: wizardData.back.file,
                    govIssuedId: null,
                    directDepositForm: null,
                    dob: me.dob,
                    gender: me.gender,
                    employmentAndIncome: [
                        {
                            title: wizardData.employmentType.value,
                            desc: `${wizardData.jobTitle}, ${wizardData.employmentBusinessName}, ${wizardData.phone}`,
                            meta: ""
                        }
                    ],
                    purposeOfBorrow: wizardData.borrowPurpose.value,
                    borrowReason: wizardData.borrowReason,
                    paymentCycleDuration: wizardData.borrowTerm.label,
                    ...loanData
                  },
                  refetchQueries: [
                      {
                          query: USER_ACTIVITY_QUERY
                      },
                      {
                          query: USER_LOAN_QUERY
                      }
                  ]
                });
            setFinish(true);
            openModal();
            
          } catch (error) {
            setTimeout(() => {
              toast.error(error.message.replace("GraphQL error: ", ""));
            }, 200);
          }
    }

    return (
        <>
        <ModalBox 
            modalIsOpen={modalIsOpen}
            openModal={openModal}
            closeModal={closeModal}
            title="Verifcation"
        >
           <h4>Application Request Completed</h4>
            <p>Congratulations, we have all that we need from you. <br />You can check the status of your application below.</p>
            <Link to={`${process.env.PUBLIC_URL}/dashboard/applications`} className="btn btn-info margin-t-sm margin-b-sm">View My Application</Link>
        </ModalBox>
        {console.log(wizardData)}
        <div className="loan-wizard step-three flex-no-wrap">
            <div className="loan-wizard__summary">
                <div className="card padding-md card--rounded card--shadow">
                    <div className="card__header">
                        <div className="d-flex justify-space-between card__title">
                            Loan Details
                            <div className="form-edit-btn"></div>
                        </div>
                    </div>
                    <div className="card__content">
                        <div className="summary-details">
                            <div className="summary-details__item">
                                <small>Borrow Amount</small>
                                <span>{wizardData.borrowAmount}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Borrow Term</small>
                                <span>{wizardData.borrowTerm.value}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Payment Frequency</small>
                                <span>{wizardData.paymentTerm.value}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Payment Start Date</small>
                                <span>{moment(wizardData.paymentCycleStartDate).format("LL")}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Admin Fee</small>
                                <span>{loanData.loanFee.value}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>{wizardData.paymentTerm.value} Payments</small>
                                <span>{loanData.paymentCycleAmount.value}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Reason / Explaination</small>
                                <span>{wizardData.borrowReason}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Purpose Of Borrow</small>
                                <span>{wizardData.borrowPurpose.value}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card padding-md card--rounded card--shadow margin-t-lg">
                    <div className="card__header">
                        <div className="d-flex justify-space-between card__title ">
                            Personal Information
                            <div className="form-edit-btn"></div>
                        </div>
                    </div>
                    <div className="card__content">
                        <div className="summary-details">
                            <div className="summary-details__item">
                                <small>Name</small>
                                <span style={{textTransform: "capitalize"}}>{me.name}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Phone</small>
                                <span>{me.phone}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Gender</small>
                                <span style={{textTransform: "capitalize"}}>{me.gender.toLowerCase()}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Date of Birth</small>
                                <span>{moment(me.dob, "DD-MM-YYYY").format("LL")}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Postal Code</small>
                                <span style={{textTransform: "capitalize"}}>{me.residentialAddress.postal}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Country</small>
                                <span>Canada</span>
                            </div>
                            <div className="summary-details__item">
                                <small>City</small>
                                <span style={{textTransform: "capitalize"}}>{me.residentialAddress.city}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Province</small>
                                <span style={{textTransform: "capitalize"}}>{me.residentialAddress.province}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Street</small>
                                <span>{me.residentialAddress.street}</span>
                            </div>
                            <div className="summary-details__item">
                                <small>Suite</small>
                                <span>{me.residentialAddress.suite}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            { loading && <div className="loading"></div> }
            {
                (loading || finish) ? "" :
                <div className="wizard-navigation justify-content-center">
                    <button onClick={previous} className="btn btn-info-outline margin-t-sm margin-b-sm" style={{marginRight: "1em"}}>Previous Step</button>
                    <button id="auth-form-btn" disabled={loading} onClick={onSubmit} className="btn btn-info margin-t-sm margin-b-sm">{loading ? "Creating" : "Create"} Application</button>
                </div>
            }
        </div>
     </>
    )
  }
  
  export default withRouter(Complete);