import React, {useState} from "react";
import NotificationsTableRow from "./NotificationsTableRow";
import EmptyTable from "./EmptyTable";
import TableHeaderFilter from "./TableHeaderFilter";

const NotificationsTable = ({title, headers = [], data}) => {
    const hasRows =  !(!data || data === null || data.length === 0);
    const [tableData, setTableData] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    const handleSetFilteredData = (data) => setTableData(data);
    const handleSetSearchInput = (value) => setSearchInput(value);
    const dataToDisplay = searchInput.length ? tableData : data;

    if(!hasRows) 
        return <EmptyTable content={"No Notifications Found"} />;

    return (
        <div className="table-responsive">
            <div className="table-wrapper">
                <TableHeaderFilter 
                    tableData={[...data]} 
                    tableTitle={title} 
                    handleSetFilteredData={handleSetFilteredData} 
                    handleSetSearchInput={handleSetSearchInput} 
                    columns={
                        [
                            {
                                label: "Date",
                                value: "createdAt"
                            },
                            {
                                label: "Title",
                                value: "title"
                            },
                            {
                                label: "Message",
                                value: "message"
                            },
                        ]
                    }
                />
                <table>
                    <thead>
                    <tr> 
                        {
                         headers.map((header, i) => (
                                <th key={i} className={header.className ? header.className : "text-left"} style={{width: header.with ? header.width : "auto"}}>{header.title}</th>
                            ))
                        }
                    </tr>
                    </thead>
                    <tbody>
                        { dataToDisplay.map((row, i) => <NotificationsTableRow key={i} {...row} /> )  }
                    </tbody>
                </table>
            </div> 
        </div>
    )
}

export default NotificationsTable;