import React from "react";

const AppButton = ({onClick}) => (
    <div className="floating-button float-button--bottom-right">
        <a onClick={onClick} className="btn btn-float btn-primary margin-t-sm margin-b-md">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g fill="none" fillRule="evenodd">
                    <g fill="#FFF">
                        <g>
                            <path d="M30 20c.552 0 1 .448 1 1v8h8c.552 0 1 .448 1 1s-.448 1-1 1h-8v8c0 .552-.448 1-1 1s-1-.448-1-1v-8.001L21 31c-.552 0-1-.448-1-1s.448-1 1-1l8-.001V21c0-.552.448-1 1-1z" transform="translate(-310 -745) translate(290 725)"/>
                        </g>
                    </g>
                </g>
            </svg>
        </a>
    </div>
);

export default AppButton;