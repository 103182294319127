import React from "react";
import IconsCheckMark from "../assets/icons/icons-checkmark.svg";

const WizardSteps = ({current}) => {
    return (
        <div className="progress-steps">
            <div className={`progress-steps__item ${current === 0 && 'active'} ${current > 0 && 'completed'}` }>
                <div className="progress-steps__icon">
                    {current > 0 ?  <img src={IconsCheckMark} alt="" /> : 1}
                </div>
                <div className="progress-steps__details">
                    <span>Step 01</span>
                    <h3>Loan Terms</h3>
                </div>
            </div>
            <div className={`progress-steps__item ${current === 1 && 'active'} ${current > 1 && 'completed'}` }>
                <div className="progress-steps__icon">
                {current > 1 ?  <img src={IconsCheckMark} alt="" /> : 2}
                </div>
                <div className="progress-steps__details">
                    <span>Step 02</span>
                    <h3>ID Upload</h3>
                </div>
            </div>
            <div className={`progress-steps__item ${current === 2 && 'active'} ${current > 2 && 'completed'}` }>
                <div className="progress-steps__icon">
                {current > 2 ?  <img src={IconsCheckMark} alt="" /> : 3}
                </div>
                <div className="progress-steps__details">
                    <span>Step 03</span>
                    <h3>Authorization</h3>
                </div>
            </div>
            <div className={`progress-steps__item ${current === 3 && 'active'}`}>
                <div className="progress-steps__icon">
                    4
                </div>
                <div className="progress-steps__details">
                    <span>Step 04</span>
                    <h3>Complete</h3>
                </div>
            </div>
        </div>
    )
}

export default WizardSteps;