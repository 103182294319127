import React from "react";
import { useQuery } from "react-apollo";
import { ActivityTable } from "../../components";
import {
    USER_ACTIVITY_QUERY
} from "../../graphql";
import { useUser } from "../../hooks";

const HEADERS = [
    {
        title: "Date",
        width: "17%",
        class: "text-left",
    },
    {
        title: "Activity",
        width: "17%",
        class: "text-left",
    },
    {
        title: "IP Address",
        width: "17%",
        class: "text-left",
    }
]

const Activity = React.memo(({sidebarState, isDesktop}) => {
    const me = useUser();
    const { loading, data } = useQuery(USER_ACTIVITY_QUERY, {
        variables: {
            id: me.id
        }
    });

    return (
    <main className="app-body main">
        {
            loading && (
                <div className="loading-overlay">
                    <div className="loading"></div>
                </div>
            )
        }
        <div className="main__title mobile-only">
            Activity
        </div>
        <div className="app-cards" style={{ transform: !sidebarState && !isDesktop ? "translateX(220px)" : "translateX(0px)" }}>
            <div className="card padding-x-lg padding-t-sm padding-b-lg card--rounded card--shadow">
                <div className="card__body d-flex">
                   {
                       loading ? "" : <ActivityTable title="Account Activity" headers={HEADERS} data={data.userActivities} />
                   }
                </div>
            </div>
        </div>
    </main>
    )
});

export default Activity;