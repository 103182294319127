import React from "react";
import currency from "currency.js";

import { Link } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';

import ScheduleCardRow from "./ScheduleCardRow";
import WelcomeCard from "./WelcomeCard";

const ScheduleCard = ({ data }) => {
    
    return (
            <>
               {
                   data && data.length > 0 ? (
                <div className="schedule-card card padding-lg card--rounded card--shadow">
                    <div className="card__header">
                        <div className="d-flex justify-space-between align-items-center card__title">
                            Loan Schedule
                            <Link to={`${process.env.PUBLIC_URL}/dashboard/transactions`} className="card__button">View All Transactions</Link>
                        </div>
                    </div>
                    <div className="card__body d-flex flex-column justify-content-center margin-t-md">
                       <Scrollbars style={{ width: "100%", height: "40vh" }}>
                        <table className="responsive-table">
                            <thead>
                            <tr>
                                <th className="text-left" style={{width: "20%"}}>Date</th>
                                <th className="text-left" style={{width: "39%"}}>Transaction</th>
                                <th className="text-center" style={{width: "18%"}}>Transaction Amount</th>
                                <th className="text-right" style={{width: "18%"}}>Current Balance</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    data[0].paymentCycleSchedule.map((schedule, index) => <ScheduleCardRow key={index} date={schedule} tAmount={currency(data[0].paymentCycleAmount).value} tBalance={data[0].loanAmount} status={schedule.status} />)
                                }
                            </tbody>
                        </table>
                        </Scrollbars>
                    </div>
                    </div>
            ) : 
            <div className="schedule-card card padding-lg card--rounded card--shadow d-flex ">
                <WelcomeCard hasCardStyle={false} /> 
            </div>
        }   
        </>
    )
};

export default ScheduleCard;