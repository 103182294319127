import { useState } from "react";

const useUpload = ({ defaultValues = {} }) => {
  const [files, setFiles] = useState({
    ...defaultValues,
  });

  const addFile = async (file, name) => {
    if (!isValidFileSize(file.size)) {
      alert("The file you are trying to upload exceeds 20 megabytes");
      return;
    }

    if (!isImage(file.name)) {
      alert("The file you are trying to upload is not a valid image");
      return;
    }

    const readData = await blobToData(file);

    setFiles((state) => ({
      ...state,
      [name]: {
        file,
        imagePreviewUrl: readData,
      },
    }));
  };

  const blobToData = (blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const removeFile = (name) => {
    setFiles((state) => ({
      ...state,
      [name]: null,
    }));
  };

  const isValidFileSize = (filesize) => {
    const size = Math.round(filesize / 1024);
    if (size <= 20024) {
      return true;
    }
  };

  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
      case "png":
        //etc
        return true;
      default:
        return false;
    }
  };

  return {
    files,
    addFile,
    removeFile,
    isValidFileSize,
    isImage,
  };
};

export default useUpload;
