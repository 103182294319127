export default function getActivity(type) {
  switch(type){
    case 'COMPLETE_PROFILE':
      return {
        title: 'Complete Profile Information',
        message: 'Successfully verified account and provided required information needed to proceed.'
      }
    case 'FILE_UPLOAD':
      return {
        title: "File Upload",
        message: "A new file was uploaded to your documents"
      }
    case 'FILE_DELETE':
        return {
          title: "File Delete",
          message: "A file was delete from your documents"
        }
    case 'EMAIL_CONFIRMATION':
      return {
        title: 'Email Confirmation',
        message: 'Confirmation was via email and awaiting verification'
      }
    case 'NEW_ACCOUNT':
      return {
        title: 'New Account Registration',
        message: 'Account username and password created and pending verification.'
      }
    case 'REVIEWING_LOAN':
      return {
        title: 'Added Loan To Review',
        message: 'You assigned a loan to review.'
      }
    case 'UNASSIGN_ADJ':
      return {
        title: 'Removed from Loan To Review',
        message: 'You have been unassigned from a loan to review.'
      }
      
    case 'ADDED_PAYMENT':
      return {
        title: 'New Payment Added',
        message: 'A new payment was added to a lendle user.'
      }
    case 'REQUEST_LOAN':
      return {
        title: 'New Funds Requested',
        message: 'You submitted a new application to request funds.'
      }
    case 'UPDATE_PROFILE':
      return {
        title: 'Profile Info Updated',
        message: 'You updated your profile information.'
      }
    case 'RESET_PASSWORD':
      return {
        title: 'Password Reset Requested',
        message: 'Link to reset password was sent to your email.'
      }
    case 'UPDATE_DOCS':
      return {
        title: 'Update Docs',
        message: 'New documents have been uploaded and changed.'
      }
    case 'UPDATE_LOAN_STATUS':
      return {
        title: 'Application Status Updated',
        message: 'Application status has been updated.'
      }
    case 'RESEND_VERIFICATION':
      return {
        title: 'Email Verification Send',
        message: 'A new reset link was sent to verify your account.'
      }
    case 'PASSWORD_UPDATED':
      return {
        title: 'Password Changed',
        message: 'Your password reset was successfully completed'
      }
    case 'CREATE_USER':
      return {
        title: 'Created User',
        message: 'A new user was created in admin mode.'
      }
    case 'UPDATE_LOAN_TERM':
      return {
        title: 'Updated Term',
        message: 'Applcation terms were changed/updated'
      }
    default:
      return {
        title: '',
        message: ''
      }
  }
}
