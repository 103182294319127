import Dashboard from "./Dashboard";
import Activity from "./Activity";
import Documents from "./Documents";
import Applications from "./Applications";
import ForgotPwd from "./Auth/ForgotPwd";
import SignIn from "./Auth/SignIn";
import SignUp from "./Auth/SignUp";
import Card from "./Card";
import CardApply from "./CardApply";
import Profile from "./Profile";
import LoanApply from "./LoanApply";
import Notifications from "./Notifications";

export {
  Activity,
  Applications,
  Documents,
  Dashboard,
  ForgotPwd,
  SignIn,
  SignUp,
  Card,
  CardApply,
  Profile,
  LoanApply,
  Notifications
};
