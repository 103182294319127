import gql from "graphql-tag";

// Return all leads (only works for admin)
export const MARKETING_LEADS_QUERY = gql`
  query MARKETING_LEADS_QUERY {
    leads {
      id
      email
      name
      loanAmount
      paymentCycleDuration
      createdAt
    }
  }
`;

// Return a lead by id (only works for admin)
export const MARKETING_LEAD_QUERY = gql`
  query MARKETING_LEAD_QUERY($id: ID!) {
    lead(id: $id) {
      id
      email
      gender
      name
      residentialAddress {
        id
        street
        city
        country
        province
        suite
        postal
      }
      phone
      documents {
        id
        bankStatement
        directDepositForm
        govIssuedId
        proofOfResidence
      }
      dob
      loanAmount
      loanFee
      loanFeeTax
      purposeOfBorrow
      borrowReason
      paymentCycleStartDate
      paymentCycleEndDate
      paymentCycleSchedule
      paymentCycleAmount
      paymentCycleFrequency
      paymentCycleDuration
      employmentAndIncome {
        id
      }
      source
      lat
      long
      verified
      hash
    }
  }
`;
