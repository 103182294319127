import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const FormDobSelect = ({ control, rules }) => {
  const d = new Date();
  const maxYears = d.getFullYear() - 1940;
  const maxMonths = 12;
  const maxDays = 31;

  const optionsYear = Array.from(
    new Array(maxYears),
    (val, index) => index + 1940
  ).map(
    (val) => ({
      value: val,
      label: val,
    }),
    []
  );

  const optionsMonth = Array.from(
    new Array(maxMonths),
    (val, index) => index + 1
  ).map(
    (val) => ({
      value: val,
      label: val,
    }),
    []
  );

  const optionsDay = Array.from(
    new Array(maxDays),
    (val, index) => index + 1
  ).map(
    (val) => ({
      value: val,
      label: val,
    }),
    []
  );

  const customStyles = {
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: 'rgba(var(--light-gray), 1)',
        }
    },
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      height: "45px",
      width: "100%",
      border: "solid 1px rgba(182, 195, 217, 0.25)",
      marginTop: "var(--space-sm)",
      marginBottom: "var(--space-sm)",
      borderRadius: "5px",
      color: "rgba(var(--black), 1)",
      paddingLeft: "5px",
      paddingTop: "2px",
      fontSize: "var(--text-xs)",
      transition: "background-color 0.5s, border-color 0.5s",
      // You can also use state.isFocused to conditionally style based on the focus state
    })
  }

  
  return (
    <>
      <Controller
        className="form-control"
        as={Select}
        control={control}
        name="dobDay"
        options={optionsDay}
        placeholder="Day"
        rules={rules}
        classNamePrefix="react-select"
        styles={customStyles}
      />
      <Controller
        as={Select}
        className="form-control"
        control={control}
        name="dobMonth"
        options={optionsMonth}
        placeholder="Month"
        rules={rules}
        classNamePrefix="react-select"
        styles={customStyles}
      />
      <Controller
        as={Select}
        className="form-control"
        control={control}
        name="dobYear"
        options={optionsYear}
        placeholder="Year"
        rules={rules}
        classNamePrefix="react-select"
        styles={customStyles}
      />
    </>
  );
};

export default FormDobSelect;
