//window.process = {};

// React
import React from "react";

// Import and Config Apollo/Graphql
import { ApolloProvider } from "react-apollo";
import { persistCache } from "apollo-cache-persist";
import { client, cache } from "./graphql/client";
import { ToastContainer } from "react-toastify";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store/index";

// Everything Else
import {Routes} from "./components";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

// Css
import "./assets/css/normalize.css";
import "./assets/css/table.css";
import "./assets/css/index.css";
import "./assets/css/app-cards.css";
import "./assets/css/profile-cards.css";
import "./assets/css/wizard.css";
import "./assets/css/loading.css";
import "./assets/css/recharts.css";
import "./assets/css/scrollbar.css";

// Libscss
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

const appRoot = document.getElementById("app-root");

async function Root() {
  // Initialize apollo's cache store
  await persistCache({
    cache,
    storage: window.localStorage,
  });

  ReactDOM.render(
    <ApolloProvider client={client}>
      
      <div className="App">
        <ToastContainer />
        <Provider store={store}>
          <Routes />
        </Provider>
      </div>
    </ApolloProvider>,
    appRoot
  );
}

Root();

serviceWorker.unregister();
