import React, { useCallback, useState } from "react";
import IconsFrontSide from "../../../assets/icons/icons-frontside.svg"
import IconsBackSide from "../../../assets/icons/icons-backside.svg"
import { toast } from "react-toastify";

import { LoanWizardZone } from "../../../components";

const Upload = ({ navigation: {next, previous}, wizardData, setWizardData}) => {
    
    const renders = React.useRef(0);
    console.log("Rending Upload: " + renders.current++)
    const [front, setFront] = useState(wizardData.front ? { ...wizardData.front } : null);
    const [back, setBack] = useState(wizardData.back ? { ...wizardData.back } : null);

    const onDrop = useCallback((acceptedFiles, event) => {
        const key = event._targetInst.return.key;

        acceptedFiles.forEach((file) => {
            console.log(file)
            const reader = new FileReader()
            reader.onabort = () => toast.error('file reading was aborted')
            reader.onerror = () => toast.error('file reading has failed')
            reader.onload = () => {
                console.log(key);
                console.log(file)
                if(key === "frontside"){
                    setFront({
                        file,
                        preview: reader.result
                    })
                }
                if(key === "backside"){
                    setBack({
                        file,
                        preview: reader.result
                    })
                   
                }
            }
            reader.readAsDataURL(file)
        })
    }, []);

    const onNext = () => {
        setWizardData({
            ...wizardData,
            front,
            back
        });
        next();
    }
    return (
        <div className="loan-wizard step-two">
            <div className="loan-wizard__dropzone">
                {
                    front ? 
                    <div className="loan-wizard__preview">
                         <div className="loan-wizard__image" style={{background: `url(${front.preview})`, backgroundSize: "cover"}}>
                    </div>
                        <h4>Front Side</h4>
                        <button onClick={() => setFront(null)} className="btn btn-info magin-t-md">Remove File</button>
                    </div>  : 
                    <LoanWizardZone 
                        onDropAccepted={onDrop} 
                        id="frontside"
                        icon={IconsFrontSide} 
                        accept={["image/png", "image/jpg", "image/jpeg"]}
                        minSize={0}
                        maxSize={5242880} 
                    />
                }
                {
                    back ? 
                    <div className="loan-wizard__preview">
                        <div className="loan-wizard__image" style={{background: `url(${back.preview})`, backgroundSize: "cover"}}>
                    </div>
                        <h4>Back Side</h4>
                        <button onClick={() => setBack(null)} className="btn btn-info margin-t-md">Remove File</button>
                    </div> : 
                    <LoanWizardZone 
                        onDropAccepted={onDrop} 
                        id="backside"
                        icon={IconsBackSide} 
                        accept={["image/png", "image/jpg", "image/jpeg"]}
                        minSize={0}
                        maxSize={5242880} 
                    />
                }
            </div>
            <div className="wizard-navigation fullWidth">
                <button onClick={previous} className="btn btn-info-outline margin-t-sm margin-b-sm">Previous Step</button>
                <button disabled={!front || !back} onClick={onNext} className="btn btn-info margin-t-sm margin-b-sm">Next Step</button>
            </div>
            
        </div>
    )
}

export default Upload;