import React from "react";

import IconsMissedArrow from "../assets/icons/missed_arrow.svg"
import IconsMadeArrow from "../assets/icons/success_arrow.svg"

const PositiveScore = () => (
    <div className="score-activity__icon score--negative">
        <img src={IconsMissedArrow} alt="up arrow" />
    </div>
);

const NegativeScore = () => (
    <div className="score-activity__icon score--positive">
        <img src={IconsMadeArrow} alt="up arrow" />
    </div>
)

const SupportCardRow = ({title, subtitle, amount, status}) => (
    <div className="score-activity__row">
        {status === "negative" && <NegativeScore />}
        {status === "positive" && <PositiveScore />}
        <div className="score-activity__details">
            <div className="score-activity__title">
                {title}
            </div>
            <div className="score-activity__subtitle">
                {subtitle}
            </div>
        </div>
        <div className="score-activity__amount">{amount}</div>
    </div>
);

export default SupportCardRow;
