import React from "react";
import { client } from "../graphql/client";
import { Link } from "react-router-dom";

import { 
    AUTH_SIGNOUT_MUTATION,
    USER_PROFILE_QUERY
} from "../graphql";

import IconsEditProfile from "../assets/icons/icons-edit-profile.svg";
import IconsLock from "../assets/icons/icons-lock.svg";
import IconsLogout from "../assets/icons/icons-log-out.svg";

const Avatar = ({ id, name }) => {
    const [isDropDownOpen, setDropDownOpen] = React.useState(false);

    const logOut = async () => {
        await client.mutate({
            mutation: AUTH_SIGNOUT_MUTATION,
            refetchQueries: [{ query: USER_PROFILE_QUERY }],
        });
        return client.cache.reset();
    };

    return(
        <div className="header__avatar" onClick={() => setDropDownOpen(!isDropDownOpen)}>
            <div className="dropdown-menu" style={{
                transform: isDropDownOpen ? "translateX(0px)" : "scaleY(0)",
                opacity: isDropDownOpen ? 1 : 0
            }}>
                <Link to={`${process.env.PUBLIC_URL}/dashboard/me`}>
                    <div className="dropdown-menu__item">
                        <div className="dropdown-menu__icon">
                            <img src={IconsEditProfile} alt="edit profile" />
                        </div>
                            <div className="dropdown-menu__link">Edit Profile</div>
                        </div>
                </Link>
                <div onClick={logOut}  className="dropdown-menu__item">
                    <div className="dropdown-menu__icon">
                        <img src={IconsLock} alt="lock screen" />
                    </div>
                   Lock Screen
                </div>
                <div onClick={logOut}  className="dropdown-menu__item">
                    <div className="dropdown-menu__icon">
                        <img src={IconsLogout} />
                    </div>
                   Log Out
                </div>
                {/* <div className="dropdown-menu__item">
                    <span style={{fontWeight: "light"}}>Acc Id:</span> {id}
                </div> */}
            </div>
            <div className="header__profilepic"></div>
            <span>{name}</span>
        </div> 
    );
};

export default Avatar;