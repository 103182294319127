import React, { useCallback, useState } from "react";
import Dropzone from "./Dropzone";
import { useMutation, useQuery } from "react-apollo";
import { toast } from "react-toastify";

import { ModalBox, DocumentFile } from "../components";

import { useUser, useModal } from "../hooks";
import { USER_UPLOAD_DOCUMENT, USER_ACTIVITY_QUERY, USER_DOCUMENTS, USER_DELETE_DOCUMENT } from "../graphql";

const DocumentFiles = () => {

    const me = useUser();
    const {loading: loadingFiles, error: errorFiles, data: userFiles, refetch } = useQuery(USER_DOCUMENTS);
    const [upload, { loading, error, data }] = useMutation(USER_UPLOAD_DOCUMENT);
    const [deleteFile, {loading: deleteLoading, error: deleteError}] = useMutation(USER_DELETE_DOCUMENT);

    const [modalIsOpen, closeModal, openModal] = useModal();
    const [modalFile, setModalFile] = useState("");

    const onView = (file) => { 
        console.log(file);    
        
        if (file
            .split(".")
            .pop()
            .toLowerCase() === "pdf"
        ) {
        window.location.href = `${
            process.env.REACT_APP_BASE_URL_PROD
        }/download?file=${encodeURI(
            file.replace(
            "uploads/",
            ""
            )
        )}`;
        } else {
            setModalFile(file); openModal(); 
        }

    }
    const onDelete = async (fileId) => {
        try {
            await deleteFile({
                variables: {
                    id: fileId
                },
                refetchQueries: [
                    {
                        query: USER_ACTIVITY_QUERY
                    },
                    {
                        query: USER_DOCUMENTS
                    }
                ]
            })
            refetch();
            toast.success("File Delete")

        } catch(error){
            toast.error(error.message.replace("GraphQL error: ", ""));
        }
    }
        
    const onDrop = useCallback( (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => toast.error('file reading was aborted')
            reader.onerror = () => toast.error('file reading has failed')
            reader.onload = () => {
                upload({
                    variables: {
                        file,
                        userId: me.id
                        },
                    refetchQueries: [{
                        query: USER_ACTIVITY_QUERY,
                    },
                    {
                        query: USER_DOCUMENTS,
                    }]
                });
                toast.success("File Added")
            }
            reader.readAsDataURL(file)
        })
      }, [me.id, upload]);

    return (
        <>
        <ModalBox  
            modalIsOpen={modalIsOpen}
            openModal={openModal}
            closeModal={closeModal}
            title={"View Document"}
        >
            <div className="modal__options">
                <img src={`${process.env.NODE_ENV === "development" ?
                     process.env.REACT_APP_BASE_URL_DEV : 
                     process.env.REACT_APP_BASE_URL_PROD}/${modalFile}`} 
                alt="Viewing file" />
            </div>
        </ModalBox>
        <div className="documents__dropzone">
            <Dropzone onDropAccepted={onDrop} 
                accept={["image/png", "image/jpg", "image/jpeg", "application/pdf"]}
                minSize={0}
                maxSize={5242880} 
            />
        </div>
        <div className="documents__files">
            {
            !loadingFiles && userFiles.documents.map((file, index) => <DocumentFile key={index} file={file} onView={onView} onDelete={onDelete} />)
            }
        </div>
        </>
    )
}

export default DocumentFiles;