import React, { useState, useEffect } from "react";

const FLINKS_LINK =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_FLINKS_PROD
    : process.env.REACT_APP_FLINKS_DEV;

const IS_DEMO = process.env.NODE_ENV !== "production";

const Authorization = ({
    navigation: {
        previous,
        next
    },
    wizardData,
    setWizardData,
}) => {

    const [showNext, setShowNext] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const flinksOnConnect = (e) => {
          const { data } = e;
          switch (data.step) {
            case "REDIRECT":
            case "ACCOUNT_SELECTED":
              setShowNext(true);
              setWizardData((state) => ({
                ...state,
                flinks: true,
              }));
              break;
            case "DISABLED_LOGIN":
              alert("Bank Login Disabled");
              break;
            case "RETRY_LATER":
              alert(
                "There was an issue connecting to your Bank, please retry in a few minutes."
              );
              break;
            case "OPERATION_DISPATCHED":
              break;
            case "OPERATION_PENDING":
              break;
            default:
              return null;
          }
        };
        window.addEventListener("message", flinksOnConnect);
        return () => window.removeEventListener("message", flinksOnConnect);
      }, []);

    const hideSpinner = () => {
        setLoading(false);
    };
    return (
        <div className="loan-wizard step-two">    
            <div className="loan-wizard__flinks">
            {
                wizardData.flinks !== null ? (
                <>
                {loading && (
                    <div>
                        <div className="loading"></div>
                    </div>
                )}
                    <iframe
                        title="Flinks Connect"
                        className="flinksconnect"
                        height="720px"
                        width="100%"
                        scrolling="no"
                        frameBorder={0}
                        onLoad={hideSpinner}
                        src={`${FLINKS_LINK}?desktopLayout=true&institutionFilterEnable=true&backgroundColor=f8f8f8&foregroundColor1=000000&foregroundColor2=000000&daysOfTransactions=Days365&demo=${IS_DEMO}`}
                    ></iframe>
                </>
            ) : (
                <span>Successfully Authorized With Your Banking Institution.</span>
            )}
            </div>
            <div className="wizard-navigation">
                <button onClick={previous} className="btn btn-info-outline margin-t-sm margin-b-sm">Previous Step</button>
                <button onClick={next} disabled={!showNext} className="btn btn-info margin-t-sm margin-b-sm">Next Step</button>
            </div>
    </div>
    )
}

export default Authorization;