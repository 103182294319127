import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";

// Components
import SignUpForm from "./SignUpForm";

// Query
import { client } from "../../../graphql/client";

import {
    AUTH_SIGNOUT_MUTATION,
    USER_PROFILE_QUERY
} from "../../../graphql";

import {
    useUser
} from "../../../hooks";

// CSS
import "./styles.css";

// Images
import logoSmall from "../../../assets/images/logo-white.png";
import logoMedium from "../../../assets/images/logo-white@2x.png";
import logoLarge from "../../../assets/images/logo-white@3x.png";

const FinalizeSignUp = ({ history }) => {
  const me = useUser();
  const onSuccess = async (result) => {
    if (result.data) {
      toast.success(
        "Successfully Registered! We sent a confirmation link to your email."
      );
      client.cache.reset();
      history.push("/dashboard/overview");
    }
  };

  const logOut = async () => {
    await client.mutate({
      mutation: AUTH_SIGNOUT_MUTATION,
      refetchQueries: [{ query: USER_PROFILE_QUERY }],
    });
    return client.cache.reset();
  };

  return (
    <div className="login-grid">
        <header className="header">
            <Link className="margin-t-xl" to={`${process.env.PUBLIC_URL}/`}>
                <img 
                    className="margin-t-md header__logo"
                    srcSet={`
                    ${logoSmall} 1x,
                    ${logoMedium} 2x,
                    ${logoLarge} 3x   
                    `}
                    alt="Lendle"
                />
            </Link>
            <p className="header__subtitle">
                ushering a new era of banking, client focused.
            </p>
        </header>
        <main className="container">
            <div className="main__cards" style={{flex: 1, paddingLeft: 0, paddingRight: 0}}>
                <div className="card padding-md card--rounded card--shadow margin">
                    <div className="card__header margin-b-md">
                        <div className="card__title">
                            Almost There, Nasir! 
                        </div>
                        <div className="card__subtitle" style={{color: "rgb(161, 174, 197)"}}>
                            Before we start we just need some information.
                        </div>
                    </div>
                    <div className="card__body d-flex flex-column">
                        <SignUpForm me={me} onSuccess={onSuccess} />
                    </div>
                </div>
            </div>
            <div className="signup__link">
                <a onClick={logOut} style={{cursor: "pointer"}}>Sign Out</a>
            </div>
        </main>
        {/* <footer className="footer">
            <div className="footer__text">
                Copyright © Lendle 2020. All Rights reserved.
            </div>
        </footer> */}
    </div>
  );
};

export default withApollo(FinalizeSignUp);
