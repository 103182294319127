import React from "react";

import EditProfileForm from "./EditProfileForm";
import EditPasswordForm from "./EditPasswordForm";

const Profile = React.memo(({sidebarState, isDesktop}) => {
    return (
    <main className="app-body main">
        <div className="main__title mobile-only">
            Credit Card
        </div>
        <div className="profile-cards" style={{ transform: !sidebarState && !isDesktop ? "translateX(220px)" : "translateX(0px)" }}>
            <EditProfileForm />
            <div className="security-box margin-desktop-l-xs card padding-md card--rounded card--shadow">
                <div className="card__title">Security</div>
                <div className="card__content">
                    <EditPasswordForm />
                </div>
            </div>
        </div>
    </main>
    )
});

export default Profile;