import React from "react";
import moment from "moment";
import { useUser } from "../hooks";

const InfoCard = () => {
    const me = useUser();
    
    const fname = me && me.name && me.name.split(" ")[0] ? me.name.split(" ")[0] : '';
    const lname = me && me.name && me.name.split(" ")[1] ? me.name.split(" ")[1] : '';
    
    return (
        <div className="info-box padding-t-xs">
            <div className="info-box__item">
                <div className="info-box__tite">First Name</div>
                <div className="info-box__content">{fname}</div>
            </div>
            <div className="info-box__item">
                <div className="info-box__tite">Last Name</div>
                <div className="info-box__content">{lname}</div>
            </div>
            <div className="info-box__item">
                <div className="info-box__tite">Phone Number</div>
                <div className="info-box__content">{me.phone}</div>
            </div>
            <div className="info-box__item">
                <div className="info-box__tite">Gender</div>
                <div className="info-box__content" style={{textTransform: "capitalize"}}>{me.gender.toLowerCase()}</div>
            </div>
            <div className="info-box__item">
                <div className="info-box__tite">Date Of Birth</div>
                <div className="info-box__content">{moment(me.dob, "DD-MM-YYYY").format("LL")}</div>
            </div>
            <div className="info-box__item">
                <div className="info-box__tite">Postal Code</div>
                <div className="info-box__content">{me.residentialAddress.postal}</div>
            </div>
            <div className="info-box__item">
                <div className="info-box__tite">Address</div>
                <div className="info-box__content">{me.residentialAddress.street}</div>
            </div>
            <div className="info-box__item">
                <div className="info-box__tite">Suite</div>
                <div className="info-box__content">{me.residentialAddress.suite}</div>
            </div>
            <div className="info-box__item">
                <div className="info-box__tite">City</div>
                <div className="info-box__content">{me.residentialAddress.city}</div>
            </div>
            <div className="info-box__item">
                <div className="info-box__tite">Province</div>
                <div className="info-box__content">{me.residentialAddress.province}</div>
            </div>
        </div>
    )
}

export default InfoCard;