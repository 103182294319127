import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import FinalizeSignUp from "../pages/Auth/FinalizeSignUp";
import VerifyAccount from "../pages/Auth/VerifyAccount";
import Page from "../layout/Page";

// import AppLayout from '../components/AppLayout';
import { useUser } from '../hooks/index';

const AuthRoute = ({ component: Component, fullWidth = false, title = "New Route", ...rest }) => {
  const me = useUser();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (me === "undefined" || me === null) {
          return (
            <Redirect
              to={`${process.env.PUBLIC_URL}/login`}
            />
          );
        }
        const needsToCompleteSignUp =
          me.residentialAddress === null ||
          me.gender === null;

        if(me.active === false){
          return <VerifyAccount />;
        }

        if (needsToCompleteSignUp) {
          // let's check if user's account is active
          return <FinalizeSignUp />;
        }

        if(fullWidth){
          return <Component {...props} />
        }
        
        return (
          <Page className="grid-home" title={title}>
            <Component {...props} />
          </Page>
        );
      }}
    />
  );
}

export default AuthRoute;
