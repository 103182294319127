import React from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import loanCalc from "../../../services/loanCalc";

import moment from "moment";

import {
    FormBorrowTermSelect,
    FormEmploymentTypeSelect, 
    FormPaymentTermSelect,
    FormBorrowPurposeSelect,
    ReactDatePicker,
    FormPhoneInput
} from "../../../components";

import { useUser } from "../../../hooks";

const Details = ({
    history,
    wizardData,
    setWizardData,
    navigation: { next, previous },
  }) => {

    const me = useUser();
    const { register, handleSubmit, errors, control, watch } = useForm({
        defaultValues: {
            ...wizardData
        }
      });

    const fname = me && me.name && me.name.split(" ")[0] ? me.name.split(" ")[0] : '';
    const lname = me && me.name && me.name.split(" ")[1] ? me.name.split(" ")[1] : '';
    
    const {
        borrowAmount,
        borrowTerm,
        paymentTerm,
        paymentCycleStartDate
    } = watch(["borrowAmount","borrowTerm", "paymentTerm", "paymentCycleStartDate"]);
    
    
    const loanData = React.useMemo(() => loanCalc(
        borrowAmount,
        borrowTerm.value,
        paymentTerm.value,
        paymentCycleStartDate
    ), [borrowAmount, paymentCycleStartDate, borrowTerm.value, paymentTerm.value]);

    const onSubmit = (data) => {
        if(data === ""){
            errors.showMessages();
            return;
        }
        setWizardData({
            ...wizardData,
            ...data
        });
        next();
    }

    return (
        <form className="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="loan-wizard step-one">
            <div className="loan-wizard__details card padding-md card--rounded card--shadow margin-desktop-r-sm overflow-visible">
                <div className="card__header">
                    <div className="card__title">
                        Loan Details
                    </div>
                </div>
                <div className="card__content">
                    {
                    Object.keys(errors).length > 0 && 
                    <div className="form__message">
                        {errors.borrowAmount && <span>Enter a borrow amount between $50 and $2000</span>}
                        {errors.borrowTerm && <span>Enter your borrow term</span>}
                        {errors.borrowPurpose && <span>Select a purpose of borrow</span>}
                        {errors.borrowReason && <span>Enter your borrow reason</span>}
                        {errors.paymentTerm && <span>Select a payment term</span>}
                        {errors.paymentCycleStartDate && <span>Select a date for your payment start date</span>}
                        {errors.jobTitle && <span>Enter your job title</span>}
                        {errors.employmentType && <span>Enter your employer's business name</span>}
                        {errors.employerBusinessName && <span>Select your employment/income type</span>}
                        {errors.phone && <span>Enter your employer's phone number</span>}
                    </div>
                    }
                    <div className="form__group">
                        <div className="form__col">
                            <input type="text" placeholder="Borrow Amount" name="borrowAmount"  pattern="\d*" maxLength={4} className="form-control" 
                            ref={register({ 
                                required: true,
                                validate: (value) => value >= 50 && value <= 2000
                            })} />
                        </div>
                        <div className="form__col form__select">
                            <FormBorrowTermSelect
                                control={control}
                                rules={{
                                    required: true
                                }}
                            />
                        </div>
                        <div className="form__col">
                            <input type="text" placeholder="Admin Fee:" className="form-control" disabled />
                            <div className="input-label__right">${loanData && loanData.loanFee ? loanData.loanFee.value : '0'}</div>
                        </div>
                    </div>
                    <div className="form__row form__select">
                        <FormBorrowPurposeSelect
                            control={control}
                            rules={{
                                required: true
                            }}
                        />
                    </div>
                    <div className="form__row">
                     <input type="text" placeholder="Why you'd like to borrow" name="borrowReason" className="form-control" ref={register({ required: true })} />
                    </div>
                    <div className="form__group">
                        <div className="form__col form__select">
                            <FormPaymentTermSelect
                                control={control}
                                rules={{
                                    required: true
                                }}
                            />
                        </div>
                        <div className="form__col">
                            <ReactDatePicker
                                control={control}
                                maxDate={moment().add("days", 30).toDate()}
                                name="paymentCycleStartDate"
                                placeholderText={
                                "When do you want to pay?"
                                }
                                rules={{ required: true }}
                            />
                        </div>
                    </div>
                    <div className="form__group">
                        <div className="form__col form__select">
                            <FormEmploymentTypeSelect
                                control={control}
                                rules={{
                                    required: true
                                }}
                            />
                        </div>
                        
                        <div className="form__col">
                            <input type="text" placeholder="Job Title" name="jobTitle" ref={register({ required: true })} className="form-control" />
                        </div>

                    </div>
                    <div className="form__group">
                        <div className="form__col">
                            <input type="text" placeholder="Employer Business Name" name="employerBusinessName" ref={register({ required: true })} className="form-control" />
                        </div>
                        <div className="form__row">
                            <FormPhoneInput
                                control={control}
                                placeholder="Employer's phone number"
                                rules={{
                                required: true,
                                validate: (value) => isValidPhoneNumber(value),
                                }}
                            />
                        </div>
                    </div>
                    
                    <div className="disclaimer">
                        <header>How does a secured mastercard work?</header>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>
            </div>
            <div className="wizard-navigation">
                <button type="submit" style={{textDecoration: "none"}} className="btn btn-info margin-t-sm margin-b-sm">Next Step</button>
            </div>
            <div className="loan-wizard__info margin-desktop-l-xs card padding-md card--rounded card--shadow">
                <div className="card__header">
                    <div className="d-flex justify-space-between card__title">
                        Personal Information
                        <button onClick={() => history.push(`${process.env.PUBLIC_URL}/dashboard/me`) } className="form-edit-btn" />
                    </div>
                </div>
                <div className="card__content">
                    <div className="info-box padding-t-xs">
                        <div className="info-box__item">
                            <div className="info-box__tite">First Name</div>
                            <div className="info-box__content">{fname}</div>
                        </div>
                        <div className="info-box__item">
                            <div className="info-box__tite">Last Name</div>
                            <div className="info-box__content">{lname}</div>
                        </div>
                        <div className="info-box__item">
                            <div className="info-box__tite">Phone Number</div>
                            <div className="info-box__content">{me.phone}</div>
                        </div>
                        <div className="info-box__item">
                            <div className="info-box__tite">Gender</div>
                            <div className="info-box__content">{me.gender.toLowerCase()}</div>
                        </div>
                        <div className="info-box__item">
                            <div className="info-box__tite">Date Of Birth</div>
                            <div className="info-box__content">{moment(me.dob, "DD-MM-YYYY").format("LL")}</div>
                        </div>
                        <div className="info-box__item">
                            <div className="info-box__tite">Postal Code</div>
                            <div className="info-box__content">{me.residentialAddress.postal}</div>
                        </div>
                        <div className="info-box__item">
                            <div className="info-box__tite">Address</div>
                            <div className="info-box__content">{me.residentialAddress.postal}</div>
                        </div>
                        <div className="info-box__item">
                            <div className="info-box__tite">Suite</div>
                            <div className="info-box__content">{me.residentialAddress.suite}</div>
                        </div>
                        <div className="info-box__item">
                            <div className="info-box__tite">City</div>
                            <div className="info-box__content">{me.residentialAddress.city}</div>
                        </div>
                        <div className="info-box__item">
                            <div className="info-box__tite">Province</div>
                            <div className="info-box__content">{me.residentialAddress.province}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </form>
    )
  }
  
  export default withRouter(Details);