import React from "react";
import moment from "moment";

const SuccessPayment = () => (
    <div className="table-pill">
        <i className="table-pill--made" alt="made payment"></i>
        <span>Successful Payment</span>
    </div>
);

const MissedPayment = () => (
    <div className="table-pill">
        <i className="table-pill--missed" alt="missed payment"></i>
        <span>Missed Payment</span>
    </div>
);

const PendingPayment = () => (
    <div className="table-pill">
        <i className="table-pill--pending" alt="missed payment"></i>
        <span>Pending Payment</span>
    </div>
);


const ScheduleCardRow = ({date, status, tAmount, tBalance}) => (
    <tr>
        <td className="table-date">{moment(date).format("LL")}</td>
        <td className="zoom">
            <PendingPayment />
            {status === "made" && <SuccessPayment />}
            {status === "missed" && <MissedPayment />}
        </td>
        <td className="table-amount zoom">{tAmount}</td>
        <td className="table-amount">{tBalance}</td>
    </tr>
);

export default ScheduleCardRow;
