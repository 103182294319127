const getUserDetails = (user) => {
  const [firstname, lastname] = user.name ? user.name.split(" ") : "";
  const [dobDay, dobMonth, dobYear] = user.dob
    ? user.dob.split("-")
    : "DD-MM-YYYY";
  const address =
    user.residentialAddress !== null
      ? user.residentialAddress
      : {
          street: null,
          postal: null,
          city: null,
          province: null,
          suite: null,
          country: null,
        };
  return {
    firstname,
    lastname,
    email: user.email,
    phone: user.phone,
    dobDay: parseInt(dobDay),
    dobMonth: parseInt(dobMonth),
    dobYear: parseInt(dobYear),
    gender: user.gender
      ? user.gender.charAt(0).toUpperCase() + user.gender.slice(1).toLowerCase()
      : null,
    ...address,
  };
};

export default getUserDetails;
