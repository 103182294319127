import React from "react";
import { useQuery } from "react-apollo";    
import { AppsTable } from "../../components";

import { USER_CARDS_QUERY, USER_LOAN_QUERY } from "../../graphql";

const HEADERS = [
    {
        title: "Date",
        width: "17%"
    },
    {
        title: "Application Id",
        width: "17%"
    },
    {
        title: "Type/Term",
        width: "17%"
    },
    {
        title: "Amount/Limit",
        width: "15%"
    },
    {
        title: "Account Manager",
        width: "17%"
    },
    {
        title: "Status",
        width: "25%"
    }
];

const Applications = React.memo(({sidebarState, isDesktop}) => {
    const { data: appsData, loading: appsLoading } = useQuery(USER_LOAN_QUERY, {
        fetchPolicy: "network-only"
    });
    const { data: cardsData, loading: cardsLoading } = useQuery(USER_CARDS_QUERY, {
        fetchPolicy: "network-only"
    });

    const finishedLoading = !appsLoading && !cardsLoading;
    const loans = finishedLoading && appsData ? appsData.loanApplications : [];
    const apps = finishedLoading && cardsData ? cardsData.cardApplications.map(card => ({
        createdAt: card.createdAt,
        id: card.id,
        paymentCycleDuration: card.cardType,
        loanAmount: card.cardLimit,
        adj: card.ajd,
        status: card.status
    })) : [];

    const data  = finishedLoading ? [...loans, ...apps].sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt) - new Date(a.createdAt);
      }) : []

    return (
        <main className="app-body main">
            {!finishedLoading && 
                <div className="loading-overlay">
                    <div className="loading"></div>
                </div>
            }
        <div className="main__title mobile-only">
                Applications
        </div>
        <div className="app-cards" style={{ transform: !sidebarState && !isDesktop ? "translateX(220px)" : "translateX(0px)" }}>
            <div className="card padding-x-lg padding-t-sm padding-b-lg card--rounded card--shadow">
                <div className="card__body d-flex">
                    <AppsTable title="Applications Overview" headers={HEADERS} data={data} />
                </div>
            </div>
        </div>
    </main>
    )
})

export default Applications;