import React from "react";
import { useQuery } from "react-apollo";

import { USER_CARDS_QUERY } from "../../graphql";

import {
    VisaCard,
    CardStatementsCard,
    SupportCard,
    SimpleAreaChart,
    CardTransactionTable,
    WelcomeCard,
    MetricsCardBox
} from "../../components";

import IconsLoan from "../../assets/svg/icons-loan.svg";
import IconsOustanding from "../../assets/svg/icons-outstanding.svg";
import IconsReusable from "../../assets/svg/icons-reusable.svg";

const Dashboard = React.memo(({history, sidebarState, isDesktop, openModal}) => {
    const { data, loading } = useQuery(USER_CARDS_QUERY, {
        fetchPolicy: "network-only",
    });
    const approvedCard =
    !loading && data && data.cardApplications
        ? data.cardApplications.filter((app) => app.status === "APPROVED")[0]
        : [];

    
    return (
        <main className="main" >
            {loading && 
                <div className="loading-overlay">
                    <div className="loading"></div>
                </div>
             }
            <div className="main__title mobile-only">
                Credit Card
            </div>
                <div className="main__cards" style={{ transform: !sidebarState && !isDesktop ? "translateX(220px)" : "translateX(0px)" }}>
                    <div className="score-card card card--rounded card--shadow d-flex flex-column">
                    <div className="card padding-t-md overflow-visible">
                        <div className="card__header">
                            <div className="card__title padding-x-md ">Account Statements</div>
                        </div>
                        <div className="card__body padding-md d-flex">
                            <VisaCard onClick={() => history.push(`${process.env.PUBLIC_URL}/card/apply`)}  lastDigits={1234} amount={approvedCard && approvedCard.length > 0  ? approvedCard.cardLimit : 0} empty={approvedCard ? false : true} />
                        </div>
                    </div>
                    <div className="card padding-md flex-full d-flex flex-column no-bg">
                        <div className="card__body flex-full"  style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr"}}>
                            <CardStatementsCard />
                        </div>
                    </div>
                </div>
                <div className="metrics-card card card--rounded card--shadow">
                    <div className="card__body">
                        <div className="chart">
                            <SimpleAreaChart data={[]} />
                        </div>
                        <div className="metrics">
                            <MetricsCardBox title="Credit" subtitle="Limit" amount={approvedCard && approvedCard.length > 0 ? approvedCard.cardLimit : 0} theme="metrics--blue" icon={<img src={IconsLoan} alt="up arrow" />} />
                            <MetricsCardBox title="Outstanding" subtitle="Balance" amount={0} theme="metrics--purple" icon={<img src={IconsOustanding} alt="up arrow" />} />
                            <MetricsCardBox title="Reusable" subtitle="Balance" amount={0} theme="metrics--cyan" icon={<img src={IconsReusable} alt="up arrow" />} />
                        </div>
                    </div>
                </div>
                {
                approvedCard ? 
                 <div className="creditcard-card card padding-lg card--rounded card--shadow">
                    <div className="card__header">
                        <div className="card__title">
                        Card Transaction
                        </div>
                    </div>
                    <div className="card__body d-flex flex-column justify-content-center margin-t-md">
                        <CardTransactionTable />
                    </div>
                    <div/> 
                </div>
                : 
                <div className="creditcard-card card padding-lg card--rounded card--shadow d-flex flex-row">
                    <WelcomeCard hasCardStyle={false} />
                </div>
            }
                 <SupportCard />
            </div>
        </main>
    )
});

export default Dashboard;