import React from "react";
import CountUp from 'react-countup';

const ScoreGauge = ({score, start = 0}) => {

    const [scoreText, setScoreText] = React.useState(`${Math.round(score * 100)}%`)
    React.useLayoutEffect(() => {
        setScoreText(Math.round(score * 100))
    }, [score])

    return(
        <div className="gauge">
            <div className="gauge__body">
                <div className="gauge__fill" style={{transform: `rotate(${score / 2}turn)` }}></div>
            </div>
            <div className="gauge__background">
                <div className="gauge__cover">
                    <span className="gauge__amount"><CountUp start={start ? start : 0} duration={1.4} end={parseInt(scoreText)} suffix="%" useEasing/></span>
                    <span className="gauge__label">Today's Score</span>
                </div>
                <div className="gauge__path" style={{transform: `rotate(${score / 2}turn) translateX(-95%) translateY(-50%)`}}>
                    <div className="gauge__needle">
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ScoreGauge;