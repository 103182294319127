import React, { PureComponent } from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const initialData = [
  {
    name: '15-30 Sep', amount: 0,
  },
  {
    name: '1-15 Oct', amount: 0,
  },
  {
    name: '15-31 Oct', amount: 0,
  },
  {
    name: '1-15 Nov', amount: 0,
  },
  {
    name: '15-30 Nov', amount: 0,
  },
  {
    name: '1-15 Dec', amount: 0,
  },
  {
    name: '16-31 Dec', amount: 0,
  },
  {
    name: '1-15 Jan', amount: 0,
  }
];

export default class SimpleAreaChart extends PureComponent {
  render() {
    const { data } = this.props;
    const formatTicket = (tick) => `$${tick}`
    return (
      <ResponsiveContainer width="99%">
        <AreaChart
          data={data.length > 0 ? data : initialData}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#0054e9" stopOpacity={0.5}/>
              <stop offset="95%" stopColor="#0054e9" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <CartesianGrid horizontal={false} strokeWidth={8} stroke={"#ecf2fd"} />
          <XAxis tickLine={false} axisLine={false} dataKey="name" tick={{ fontSize: "0.8em", fill: "#a1aec5" }} />
          <YAxis  domain={[0, 10000]} tickLine={false} tickFormatter={formatTicket} axisLine={false} tick={{ fontSize: "0.8em", fill: "#a1aec5" }} />
          <Tooltip />
          <Area type="monotone" dataKey="amount" stroke="#0054e9" strokeWidth={4} fill="url(#colorUv)" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}