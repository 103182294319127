import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { useForm } from "react-hook-form";
import { useUser } from "../../hooks";
import { toast } from "react-toastify";

import IconsEye from "../../assets/svg/icons-eye.svg";

import { USER_PASSWORD_UPDATE_QUERY } from "../../graphql";

import {
    FormPasswordInput
} from "../../components";

const EditPasswordForm = () => {
    const me = useUser();

    // Setup for ReactPasswordStrength Component
    const [passData, setPassData] = useState({
        score: null,
        password: null,
    });

    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        watch,
        reset
    } = useForm();

    const [
        updatePassword,
        { loading: passwordUpdateLoading, error: passwordUpdateError },
      ] = useMutation(USER_PASSWORD_UPDATE_QUERY);
    

    const onSubmit = async (data) => {

        if (data === "") {
            errors.showMessages();
            return;
        }

        try {
            await updatePassword({
              query: USER_PASSWORD_UPDATE_QUERY,
              variables: {
                id: me.id,
                email: me.email,
                password: data.currentPassword,
                emailPassword: false,
                newPassword: data.password,
                confirmPassword: data.confirmPassword
              },
            });
            toast.success("Password Successfully Updated");
            reset();
          } catch (error) {
            toast.error(error.message.replace("GraphQL error: ", ""));
          }
    }

    return (
        <form className="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            {
                passwordUpdateLoading && <div className="loading"></div>
            }
            {
                  Object.keys(errors).length > 0 && <div className="form__message" style={{flexBasis: "100%"}}>
                  {errors.currentPassword && <span>Current password is required</span>}
                    {errors.password && <span>New password is required</span>}
                    {errors.confirmPassword && (
              <span>
                {errors.confirmPassword.message !== ""
                  ? errors.confirmPassword.message
                  : "Confirm password is required"}
              </span>
            )}
            </div>
            
            }
            <div className="form__row">
                <input type="text" value={me.email} name="email" placeholder="Email" className="form-control" disabled />
            </div>
            <div className="form__row">
                <div className="input-with-icon">
                    <input 
                        type="password" 
                        className="form-control"
                        name="currentPassword"
                        placeholder="Current Password"
                        ref={register({
                            required: true,
                        })}
                        control={control}
                        style={{paddingTop:"16px"}} 
                    />
                        <div className="input-icon-div">
                        <img src={IconsEye} alt="" />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <FormPasswordInput
                    strength
                    controlled
                    placeholder="New Password"
                    changeCallback={({ score, password, isValid }) => {
                        setValue("password", password, { shouldValidate: true });
                        setPassData({
                            password,
                            score,
                            isValid,
                        });
                    }}
                    control={control}
                    rules={{
                    required: true,
                    validate: () => passData.isValid,
                    }}
                />  
            </div>
            <div className="form__row">
                <div className="input-with-icon">
                    <input 
                        type="password" 
                        className="form-control"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        ref={register({
                            required: true,
                            validate: (value) =>
                              value === watch("password") || "Passwords don't match.",
                          })}
                        control={control}
                        style={{paddingTop:"16px"}} 
                    />
                        <div className="input-icon-div">
                        <img src={IconsEye} alt="" />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <button id="auth-form-btn" style={{textDecoration: "none", width: "100%"}} className="btn btn-info-outline margin-t-sm margin-b-md">Save Password</button>
            </div>
        </form>
    );
}

export default EditPasswordForm;