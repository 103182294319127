import { DirectiveLocation } from "graphql";
import React from "react";
import { withRouter, Link } from "react-router-dom";

import { LangWidget } from "../components";

import logoSmall from "../assets/images/logo.png";
import logoMedium from "../assets/images/logo@2x.png";
import logoLarge from "../assets/images/logo@3x.png";

const Sidenav = ({ location, isDesktop, sidebarState }) => {
    const { pathname } = location;

    return (
        <aside className="sidenav" style={{ transition: isDesktop ? "none" : "transform 0.5s ease-in-out", transform: !sidebarState ?  "translateX(0px)" : isDesktop ?  "translateX(0px)" : "translateX(-220px)" }}>
                <div className="sidenav__logo">
                    <img 
                    srcSet={`
                    ${logoSmall} 1x,
                    ${logoMedium} 2x,
                    ${logoLarge} 3x   
                    `}
                    alt="Lendle"
                    />
                </div>
                <nav className="sidenav__menu">
                <Link to={`${process.env.PUBLIC_URL}/dashboard/overview`}  className={`sidenav__item ${ pathname === '/dashboard/overview' && 'active' }`}>
                    <div className={`sidenav__icon ${ pathname === '/dashboard/overview' && 'active' }`}>
                        <svg width="18" height="16" viewBox="0 0 18 16">
                            <g fillRule="nonzero">
                                <path d="M17.698 7.601L9.622.242c-.355-.323-.89-.323-1.244 0L.302 7.602c-.284.258-.377.657-.239 1.015.139.358.477.59.861.59h1.29l-.182 5.762c-.017.552.416 1.013.969 1.03l.031.001h2.667c.553 0 1-.448 1-1v-1.897c0-.553.448-1 1-1h2.602c.552 0 1 .447 1 1V15c0 .552.448 1 1 1h2.485c.552 0 1-.448 1-1V9.206h1.29c.384 0 .722-.231.861-.59.139-.357.045-.756-.24-1.015z" transform="translate(-41 -132) translate(41 132)"/>
                            </g>
                        </svg>     
                    </div>
                    <div className={`sidenav__link ${ pathname === '/dashboard/overview' && 'active'}`}>
                        Home
                    </div>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/dashboard/applications`}  className={`sidenav__item ${ pathname === '/dashboard/applications' && 'active' }`}>
                    <div className={`sidenav__icon ${ pathname === '/dashboard/applications' && 'active' }`}>
                        <svg width="20" height="13" viewBox="0 0 20 13">
                            <g fillRule="nonzero">
                                <g transform="translate(-40 -198) translate(40 198)">
                                    <rect width="3" height="3" rx="1.5"/>
                                    <rect width="3" height="3" y="5" rx="1.5"/>
                                    <rect width="3" height="3" y="10" rx="1.5"/>
                                    <path d="M6.5 10h12c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-12c-.828 0-1.5-.672-1.5-1.5S5.672 10 6.5 10zM6.5 5h12c.828 0 1.5.672 1.5 1.5S19.328 8 18.5 8h-12C5.672 8 5 7.328 5 6.5S5.672 5 6.5 5zM6.5 0h12c.828 0 1.5.672 1.5 1.5S19.328 3 18.5 3h-12C5.672 3 5 2.328 5 1.5S5.672 0 6.5 0z"/>
                                </g>
                            </g>
                        </svg>       
                    </div>
                    <div className={`sidenav__link ${ pathname === '/dashboard/applications' && 'active' }`}>
                        Applications
                    </div>
                </Link>

                <Link to={`${process.env.PUBLIC_URL}/dashboard/documents`}  className={`sidenav__item ${ pathname === '/dashboard/documents' && 'active' }`}>
                    <div  className={`sidenav__icon ${ pathname === '/dashboard/documents' && 'active' }`}>
                        <svg width="20" height="16" viewBox="0 0 20 16">
                            <g fillRule="nonzero">
                                <g>
                                    <path d="M16 0c2.142 0 3.891 1.684 3.995 3.8L20 4v8c0 2.142-1.684 3.891-3.8 3.995L16 16H4C1.858 16 .109 14.316.005 12.2L0 12V4C0 1.858 1.684.109 3.8.005L4 0h12zM6.5 4C5.12 4 4 5.12 4 6.5c0 .47.13.911.356 1.287C3.51 8.513 3 9.679 3 11c0 .552.448 1 1 1s1-.448 1-1c0-1.197.647-2 1.5-2S8 9.803 8 11c0 .552.448 1 1 1s1-.448 1-1c0-1.32-.509-2.487-1.356-3.214C8.87 7.411 9 6.971 9 6.5 9 5.12 7.88 4 6.5 4zm9.5 6h-3c-.552 0-1 .448-1 1s.448 1 1 1h3c.552 0 1-.448 1-1s-.448-1-1-1zm0-3h-3c-.552 0-1 .448-1 1s.448 1 1 1h3c.552 0 1-.448 1-1s-.448-1-1-1zM6.5 6c.276 0 .5.224.5.5s-.224.5-.5.5-.5-.224-.5-.5.224-.5.5-.5zM16 4h-3c-.552 0-1 .448-1 1s.448 1 1 1h3c.552 0 1-.448 1-1s-.448-1-1-1z" transform="translate(-40 -261) translate(40 261)"/>
                                </g>
                            </g>
                        </svg>    
                    </div>
                    <div className={`sidenav__link ${ pathname === '/dashboard/documents' && 'active' }`}>
                        Documents
                    </div>
                </Link>

                <Link to={`${process.env.PUBLIC_URL}/dashboard/activity`} className={`sidenav__item ${ pathname === '/dashboard/activity' && 'active' }`}>
                    <div className={`sidenav__icon ${ pathname === '/dashboard/activity' && 'active' }`}>
                        <svg width="16" height="20" viewBox="0 0 16 20">
                            <g fillRule="nonzero">
                                <path d="M2 7c1.105 0 2 .895 2 2v9c0 1.105-.895 2-2 2s-2-.895-2-2V9c0-1.105.895-2 2-2zM8 4c1.105 0 2 .895 2 2v12c0 1.105-.895 2-2 2s-2-.895-2-2V6c0-1.105.895-2 2-2zM14 0c1.105 0 2 .895 2 2v16c0 1.105-.895 2-2 2s-2-.895-2-2V2c0-1.105.895-2 2-2z" transform="translate(-42 -327) translate(42 327)"/>
                            </g>
                        </svg>   
                    </div>
                    <div className={`sidenav__link ${ pathname === '/dashboard/activity' && 'active' }`}>
                        Activity
                    </div>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/dashboard/notifications`} className={`sidenav__item ${ pathname === '/dashboard/notifications' && 'active' }`}>
                    <div className={`sidenav__icon ${ pathname === '/dashboard/notifications' && 'active' }`}>
                       
                        <svg width="16" height="18" viewBox="0 0 16 18">
                            <g fillRule="evenodd">
                                <g>
                                    <g>
                                        <g transform="translate(-216 -53) translate(216 45) translate(0 8)">
                                            <path d="M8 1c3.314 0 6 2.686 6 6v7H2V7c0-3.314 2.686-6 6-6z"/>
                                            <rect width="2" height="3" x="7" rx="1"/>
                                            <path d="M2 12h12l1.6 1.2c.252.189.4.485.4.8v.25c0 .414-.336.75-.75.75H.75c-.414 0-.75-.336-.75-.75V14c0-.315.148-.611.4-.8L2 12zM10 16c0 1.105-.895 2-2 2s-2-.895-2-2h4z"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className={`sidenav__link ${ pathname === '/dashboard/notifications' && 'active' }`}>
                        Notifications
                    </div>
                </Link>

                <Link to={`${process.env.PUBLIC_URL}/card`} className={`sidenav__item ${ pathname === '/card' && 'active' }`}>
                    <div className={`sidenav__icon ${ pathname === '/card' && 'active' }`}>
                        <svg width="20" height="16" viewBox="0 0 20 16">
                            <g fillRule="evenodd">
                                <g fillRule="nonzero">
                                    <g>
                                        <path d="M20 4.716V3.182C20 1.425 18.727 0 17.156 0H2.844C1.274 0 0 1.425 0 3.182v1.534C0 4.873.114 5 .254 5h19.492c.14 0 .254-.127.254-.284zM0 7.26v5.837C0 14.7 1.273 16 2.844 16h14.312C18.726 16 20 14.7 20 13.097V7.26c0-.143-.114-.259-.254-.259H.254C.114 7 0 7.116 0 7.26zM6 12c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-1c0-.552.448-1 1-1h1c.552 0 1 .448 1 1v1z" transform="translate(-40 -397) translate(40 397)"/>
                                    </g>
                                </g>
                            </g>
                        </svg>     
                    </div>
                    <div className={`sidenav__link ${ pathname === '/card' && 'active' }`}>
                        Credit Card
                    </div>
                </Link>
          
                </nav>
                <div className="nav__footer">
                    <LangWidget/>
                </div>
            </aside>
    )
}

export default withRouter(Sidenav);