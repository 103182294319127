import React, { useState, useEffect } from "react";
import moment from "moment";

import { 
    WizardHeader, 
    WizardSteps, 
    Avatar 
} from "../../components";

import { 
    Details,
    Upload,
    Authorization,
    Complete
} from "./WizardSteps";

import { useUser, useStep } from "../../hooks";

import { steps } from "../../constant";


const LoanApply = () => {
    const me = useUser();

    const [wizardData, setWizardData] = useState({
        borrowAmount: 500,
        borrowTerm: {
            value: 3,
            label: "3 Months"
        },
        paymentTerm: {
            value: "biweekly",
            label: "Bi-Weekly"
        },
        paymentCycleStartDate: moment().toDate(),
        flinks: process.env.NODE_ENV === "production" ? null : true,
      });

    const { step, navigation, index } = useStep({ initialStep: 0, steps });

    const { id } = step;

    const props = {
        navigation,
        wizardData,
        setWizardData,
    };

    const resetWizard = () => {
        setWizardData({
            flinks: process.env.NODE_ENV === "production" ? null : true,
         });
        navigation.goTo(0);
    };


    useEffect(() => {
        resetWizard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="wizard-container grid-home">
            <WizardHeader>
                <WizardSteps current={index} />
                <Avatar name={me.name} />   
            </WizardHeader>
            <WizardSteps current={index} />
            {/* {
                process.env.NODE_ENV === "development" && 
                <div className="pretty-code">
                    <pre>{JSON.stringify(wizardData, undefined, 2)}</pre>
                </div>
            } */}
            <main className="main">
                {
                    id !== "Step 3" && (
                        <div className="wizard-header">
                            <h2>Loan Terms</h2>
                            <span>Fill out the form with details</span>
                        </div>
                    )
                }
                {id === "Step 1" && <Details {...props} />}
                {id === "Step 2" && <Upload {...props} />}
                {id === "Step 3" && <Authorization {...props} />}
                {id === "Step 4" && <Complete {...props} />}
            </main>
            <footer className="footer">
                <div className="footer__text">
                    Copyright © Lendle 2020. All Rights reserved.
                </div>
            </footer>
        </div>
    )
};

export default LoanApply;