import React from "react";

const CardTransactionTable = () => {
    return (
        <table className="responsive-table">
            <thead>
            <tr>
                <th className="text-left" style={{width: "20%"}}>Date</th>
                <th className="text-left" style={{width: "39%"}}>Transaction</th>
                <th className="text-right" style={{width: "18%"}}>Transaction Amount</th>
                <th className="text-right" style={{width: "18%"}}>Current Balance</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className="table-date">20.08.2020</td>
                <td>
                    <div className="table-pill">
                        <i className="table-pill--made" alt="missed payment"></i>
                        <span>Successful Payment</span>
                    </div>
                </td>
                <td className="table-amount">50</td>
                <td className="table-amount">1950</td>
            </tr>
            <tr>
                <td className="table-date">20.08.2020</td>
                <td>
                    <div className="table-pill">
                        <i className="table-pill--missed" alt="missed payment"></i>
                        <span>Missed Payment</span>
                    </div>
                </td>
                <td className="table-amount">50</td>
                <td className="table-amount">1950</td>
            </tr>
            <tr>
                <td className="table-date">20.08.2020</td>
                <td>
                    <div className="table-pill">
                        <i className="table-pill--made" alt="missed payment"></i>
                        <span>Successful Payment</span>
                    </div>
                </td>
                <td className="table-amount">50</td>
                <td className="table-amount">1950</td>
            </tr>
            <tr>
                <td className="table-date">20.08.2020</td>
                <td>
                    <div className="table-pill">
                        <i className="table-pill--made" alt="missed payment"></i>
                        <span>Successful Payment</span>
                    </div>
                </td>
                <td className="table-amount">50</td>
                <td className="table-amount">1950</td>
            </tr>
            </tbody>
        </table>
    )
};

export default CardTransactionTable;